import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'ck_commun/src/app/modUtils/components/componentsLibrary/spinner/LoadingSpinner';

export default function LoadingStatusForm({ status, message, loading }) {
    const { t } = useTranslation();

    const colorVariants = {
        loading:
            'bg-primary-color-100 border-primary-color-400 text-primary-color-700',
        success:
            'bg-success-color-100 border-success-color-400 text-success-color-700',
        error: 'bg-error-color-100 border-error-color-400 text-destructive-color-700',
    };

    const MessageVariants = {
        success: t('loadingStatusForm.success'),
        error: t('loadingStatusForm.error'),
    };

    return (
        <div
            className={`border px-4 py-3 rounded relative mb-5 mt-2.5
                    ${loading ? colorVariants['loading'] : status && colorVariants[status]}
                `}
        >
            {loading ? (
                <>
                    <span>{t('loadingStatusForm.loading')}</span>
                    <LoadingSpinner className={'text-primary-color'} />
                </>
            ) : (
                status && <span>{message || MessageVariants[status]}</span>
            )}
        </div>
    );
}
