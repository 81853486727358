import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Table,
    Select,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { amountFormatWithCurrencySymbol } from '../../modUtils/functions/numberFormat';
import { getDateSubtractedFromDateSource } from '../../modUtils/functions/dateFormat';
import { get_dashboard_top_sites_by_group } from '../slices/cumulativeSlice';
import { message, Spin } from 'antd';
import AlertMessage from '../../modUtils/components/AlertMessage';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { selectOptions } from '@testing-library/user-event/dist/cjs/setup/directApi.js';

const TopSitesByGroupTable = ({ numberOfSites, duree, siteList }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const todayDate = moment().unix();
    const [loading, setLoading] = useState(false);
    const [startDateTopSites, setStartDateTopSites] = useState(
        getDateSubtractedFromDateSource(todayDate, duree[0].value),
    );
    const endDateTopSites = todayDate;
    const { dashboardTopSitesByGroup } = useSelector(
        (state) => state.cumulative,
    );
    //fonction d'appel à get_dashboard_top_sites_by_group
    const getDashboardTopSites = async () => {
        setLoading(true);
        try {
            await dispatch(
                get_dashboard_top_sites_by_group({
                    groupId,
                    startDate: startDateTopSites,
                    endDate: endDateTopSites,
                    sites: siteList?.join(','),
                }),
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('TopSitesGrowthByGroup.error')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (groupId && startDateTopSites && endDateTopSites) {
            getDashboardTopSites();
        }
    }, [groupId, i18next.language]);

    const topSitesColumns = [
        {
            title: t('topSitesCol.positionCol'),
            dataIndex: 'position',
            key: 'position',
            width: '5%',
            align: 'right',
        },
        {
            title: t('topSitesCol.siteNameCol'),
            dataIndex: 'site_name',
            key: 'site_name',
            width: '50%',
            align: 'left',
        },
        {
            title: t('topSitesCol.totalCol'),
            dataIndex: 'total',
            key: 'total',
            width: '15%',
            align: 'right',
            render: (text) => amountFormatWithCurrencySymbol(text),
        },
    ];

    const handleChangeTopSites = (value) => {
        setStartDateTopSites(getDateSubtractedFromDateSource(todayDate, value));
        getDashboardTopSites();
    };

    return (
        <>
            <div className='mb-[10px] flex items-center justify-around sm:justify-between flex-wrap '>
                <div className='text-primary-color brightness-[70%] text-[13px] font-semibold text-center text-nowrap'>
                    {t('TopSitesByGroup.title')}
                </div>
                <div className='select-range'>
                    <Select
                        defaultActiveFirstOption={true}
                        dropdownMatchSelectWidth={true}
                        options={duree}
                        onSelect={(value, option) =>
                            handleChangeTopSites(value)
                        }
                        style={{
                            select: { width: '160px' },
                            selectOptions: { width: '160px' },
                        }}
                    />
                </div>
            </div>
            {loading ? (
                <Spin />
            ) : (
                <Table
                    dataSource={dashboardTopSitesByGroup}
                    columns={topSitesColumns}
                    //scroll={{ y: 140 }}
                />
            )}
        </>
    );
};

export default TopSitesByGroupTable;
