import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Input,
    Modal,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import EquipmentUpdateModal from '../equipmentUpdateModal/EquipmentUpdateModal';
import DevicesManageModal from '../devicesManageModal/DevicesManageModal';
import EquipmentConnectionToServerButton from '../equipmentConnectionToServerButton/EquipmentConnectionToServerButton';
import { dateFormat } from '../../../modUtils/functions/dateFormat';

export default function EquipmentManageModal({ equipment, siteName }) {
    const [modalVisible, setModalVisible] = useState(false);
    const { t } = useTranslation();

    const closeModal = () => {
        setModalVisible(false);
    };
    const modalFooter = (
        <div className='flex flex-row justify-around items-center'>
            <EquipmentUpdateModal
                closeModal={closeModal}
                buttonType='button'
                equipment={equipment}
            />
            <EquipmentConnectionToServerButton
                buttonType='button'
                equipment={equipment}
            />
            <DevicesManageModal
                closeModal={closeModal}
                equipment={equipment}
                siteName={siteName}
                buttonType='button'
            />
        </div>
    );
    return (
        <>
            <div
                className='font-bold text-base leading-[19px] text-left cursor-pointer'
                onClick={() => setModalVisible(true)}
            >
                {equipment.name}
            </div>
            <Modal
                title={t('equipment.transEquipmentManageModal')}
                width={700}
                isOpen={modalVisible}
                onClose={() => setModalVisible(false)}
                footer={modalFooter}
            >
                <span className='text-muted text-primary-color absolute top-4 left-4'>
                    id: {equipment.id}
                </span>

                {equipment?.name && (
                    <Input
                        label={t('equipment.transEquipmentNameLabel')}
                        defaultValue={
                            equipment.name === null ? '-' : equipment.name
                        }
                        disabled
                    />
                )}

                {equipment?.serial_number && (
                    <Input
                        label={t('equipment.transEquipmentSNLabel')}
                        defaultValue={equipment.serial_number}
                        disabled
                    />
                )}
                <Input
                    label={t('equipment.transEquipmentLastUpdate')}
                    defaultValue={
                        equipment?.last_seen === null
                            ? ''
                            : dateFormat(equipment?.last_seen)
                    }
                    disabled
                />

                <Input
                    label='Software ID / Version'
                    defaultValue={`${
                        equipment?.software_id === null
                            ? '-'
                            : equipment?.software_id
                    } / ${equipment?.software_version === null ? '-' : equipment?.software_version}`}
                    disabled
                />

                <Input
                    label='Hardware ID / Version'
                    defaultValue={`${equipment?.hardware_id === null ? '-' : equipment?.hardware_id} / ${
                        equipment?.hardware_version === null
                            ? '-'
                            : equipment?.hardware_version
                    }`}
                    disabled
                />
            </Modal>
        </>
    );
}
