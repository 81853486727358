import { useTranslation } from 'react-i18next';
import {
    Input,
    Modal,
    Table,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import { useState } from 'react';
import {
    create_promotion_supports,
    get_promotion_supports,
} from '../../slices/promotionSlice';
import { message, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { LoadingOutlined } from '@ant-design/icons';

const ModalGenerateQrCode = ({
    generatedCodes,
    modalGenerateQrCode,
    toggleModalGenerateQrCode,
    promotionId,
    groupId,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [nbGeneratedCode, setNbGeneratedCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleDownload = () => {
        const fileUrl =
            'https://www.osureunion.fr/wp-content/uploads/2022/03/pdf-exemple.pdf'; // à changer
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = 'blank';
        link.download = 'pdf-exemple.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const columns = [
        {
            title: t(
                'modalViewTickets.modalGenerateCodes.tableGeneratedCodes.transCreatedDate',
            ),
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: t(
                'modalViewTickets.modalGenerateCodes.tableGeneratedCodes.transNumberOfCodesGenerated',
            ),
            dataIndex: 'numberOfCodes',
            key: 'numberOfCodes',
        },
        {
            title: t(
                'modalViewTickets.modalGenerateCodes.tableGeneratedCodes.transAction',
            ),
            dataIndex: '',
            key: '',
            render: (text, record) => (
                <Button
                    color={'primary'}
                    onClick={handleDownload}
                    className={'text-xs px-5 py-1.5 my-1.5'}
                >
                    {t(
                        'modalViewTickets.modalGenerateCodes.tableGeneratedCodes.transDownloadFile',
                    )}
                </Button>
            ),
        },
    ];

    const refreshSupports = () => {
        dispatch(
            get_promotion_supports({
                groupId,
                promotion: promotionId,
                limit: 5,
                offset: 1,
            }),
        );
    };

    const generateCode = async () => {
        setLoading(true);
        try {
            const response = await dispatch(
                create_promotion_supports({
                    groupId,
                    promotionId,
                    amount_to_generate: nbGeneratedCode,
                }),
            ).unwrap();

            if (response) {
                message.success({
                    content: (
                        <AlertMessage
                            status={response?.status}
                            alertMessage={t(
                                'success.transSuccessGenerateSupport',
                            )}
                        />
                    ),
                });
                toggleModalGenerateQrCode();
                refreshSupports();
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('errors.transErrorGenerateSupport')}
                        errorDetail={error?.message}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isOpen={modalGenerateQrCode}
            onClose={toggleModalGenerateQrCode}
            title={t('modalViewTickets.transGenerateCodes')}
            style={{
                modalTitle: {
                    color: '#25292D',
                    fontSize: '20px',
                    lineHeight: '23px',
                },
            }}
        >
            <div className='flex items-end gap-2 mb-4'>
                <Input
                    label={t(
                        'modalViewTickets.modalGenerateCodes.transNumberCodes',
                    )}
                    style={{ marginBottom: 0 }}
                    placeholder={'20'}
                    onChange={(e) => setNbGeneratedCode(e.target.value)}
                />
                <Button
                    disabled={loading || !nbGeneratedCode}
                    color={'success'}
                    onClick={generateCode}
                    className={'min-w-[120px]'}
                >
                    {loading ? (
                        <Spin
                            indicator={
                                <LoadingOutlined twoToneColor='#fff' spin />
                            }
                        />
                    ) : (
                        t(
                            'modalViewTickets.modalGenerateCodes.transGenerateButton',
                        )
                    )}
                </Button>
            </div>
            {/* <div>
                <span className='text-sm font-semibold'>
                    {t(
                        'modalViewTickets.modalGenerateCodes.transCodesAlreadyGenerated',
                    )}
                </span>
                <Table
                    columns={columns}
                    dataSource={generatedCodes || []}
                    noDataMessage={t('transNoData')}
                />
            </div> */}
        </Modal>
    );
};

export default ModalGenerateQrCode;
