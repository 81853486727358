import { useTranslation } from 'react-i18next';
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
    close_promotion_support,
    get_promotion_support_detail,
} from '../../slices/promotionSlice';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { message } from 'antd';
import { useEffect } from 'react';

const ModalCloseTicket = ({
    toggleModalCloseTicket,
    modalCloseTicket,
    supportId,
    promotionId,
    groupId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { supportDetail } = useSelector((state) => state.promotion);

    useEffect(() => {
        dispatch(
            get_promotion_support_detail({
                groupId,
                promotionId,
                supportId,
            }),
        );
    }, [supportId]);
    const closeTicket = () => {
        try {
            const response = dispatch(
                close_promotion_support({
                    groupId,
                    promotionId,
                    supportId,
                }),
            ).unwrap();
            if (response) {
                message.success({
                    content: (
                        <AlertMessage
                            status={response?.status}
                            alertMessage={t('success.transSuccessCloseSupport')}
                        />
                    ),
                });
                toggleModalCloseTicket();
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('errors.transErrorCloseSupport')}
                        errorDetail={error?.data.message}
                    />
                ),
            });
        }
    };

    return (
        <Modal
            isOpen={modalCloseTicket}
            onClose={toggleModalCloseTicket}
            title={t('modalViewTickets.modalCloseTicket.transTitle')}
        >
            <div className='text-center'>
                <p className='text-sm'>
                    {t(
                        'modalViewTickets.modalCloseTicket.transCloseTicketConfirmMessage',
                    )}
                </p>
                <p className='text-sm font-semibold'>
                    N°: {supportDetail?.support_number}
                </p>
                <p className='text-sm font-semibold'>
                    {supportDetail?.promotion.name}
                </p>
                <Button color={'success'} onClick={closeTicket}>
                    {t('modalViewTickets.transCloseCode')}
                </Button>
            </div>
        </Modal>
    );
};

export default ModalCloseTicket;
