import React from 'react';
import PropTypes from 'prop-types';

const Card = ({
    title,
    width,
    height,
    key,
    style = {},
    className = '',
    ...props
}) => {
    //ajouter dans scss test dépassement height

    return (
        <div
            id={key}
            className={`border border-solid border-border-color rounded-[10px] text-center bg-card-color shadow-xl overflow-hidden ${className}`}
            style={{ width: width, height: height, ...style.card }}
        >
            {title && (
                <div
                    className='bg-primary-color flex justify-center items-center h-[33px] rounded-tl-lg rounded-tr-lg p-1.5'
                    style={{ ...style.cardHeader }}
                >
                    <span
                        className='text-primary-foreground-color text-xs leading-[12px]'
                        style={{ ...style.cardTitle }}
                    >
                        {title}
                    </span>
                </div>
            )}
            <div
                className='p-[6px_10px] box-content flex flex-col justify-center bg-card-color'
                style={{ ...style.cardBody }}
            >
                {props.children}
            </div>
        </div>
    );
};

Card.defaultProps = {
    width: 'max-width',
    height: 'max-height',
};

Card.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Card;
