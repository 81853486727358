import { useEffect, useState } from 'react';
import { Label } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import { CgAddR } from 'react-icons/cg';
import { FaRegMinusSquare } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { TimePicker } from 'antd';
import moment from 'moment';

const RepeaterTime = ({
    startTimeLabel,
    endTimeLabel,
    disabled,
    setTimeSlots,
    timeSlots,
}) => {
    const formatDate = 'HH:mm';
    const { t } = useTranslation();
    const [repeaterItems, setRepeaterItems] = useState(
        timeSlots.length
            ? timeSlots
            : [{ slot_hours_start: '', slot_hours_end: '', error: '' }],
    );

    const addItem = () => {
        const lastItem = repeaterItems[0];

        // Validation: Ensure the last item has both times filled
        if (!lastItem.slot_hours_start || !lastItem.slot_hours_end) {
            setRepeaterItems(
                repeaterItems.map((item, index) =>
                    index === 0
                        ? {
                              ...item,
                              error: t(
                                  'createPromotionModal.stepperCreatePromotion.validationForm.transRequiredStartAndEndTime',
                              ),
                          }
                        : item,
                ),
            );
            return;
        }

        // Validation: Ensure end time is greater than start time
        if (lastItem.slot_hours_end <= lastItem.slot_hours_start) {
            setRepeaterItems(
                repeaterItems.map((item, index) =>
                    index === 0
                        ? {
                              ...item,
                              error: t(
                                  'createPromotionModal.stepperCreatePromotion.validationForm.transRequiredStartAndEndTime',
                              ),
                          }
                        : item,
                ),
            );
            return;
        }

        setRepeaterItems([
            { slot_hours_start: '', slot_hours_end: '', error: '' },
            ...repeaterItems,
        ]);
    };

    const updateItem = (index, name, value) => {
        const updatedItems = [...repeaterItems];
        updatedItems[index] = {
            ...updatedItems[index],
            [name]: value,
            error: '',
        };
        setRepeaterItems(updatedItems);
    };

    const removeItem = (index) => {
        setRepeaterItems(repeaterItems.filter((_, i) => i !== index));
    };

    useEffect(() => {
        setTimeSlots(
            repeaterItems.map((item) => ({
                slot_hours_start: item.slot_hours_start,
                slot_hours_end: item.slot_hours_end,
            })),
        );
    }, [repeaterItems, setTimeSlots]);

    return (
        <>
            {repeaterItems.map((item, index) => (
                <div
                    className={`flex flex-col gap-1 mt-2${disabled ? ' cursor-not-allowed opacity-25' : ''}`}
                    key={index}
                >
                    <div className='flex items-end gap-2'>
                        <div>
                            <Label label={startTimeLabel} />
                            <TimePicker
                                format={formatDate}
                                value={
                                    item.slot_hours_start
                                        ? moment(
                                              item.slot_hours_start,
                                              formatDate,
                                          )
                                        : null
                                }
                                onChange={(time, timeString) =>
                                    updateItem(
                                        index,
                                        'slot_hours_start',
                                        moment(timeString, formatDate),
                                    )
                                }
                                use12Hours={false}
                                disabled={disabled}
                            />
                        </div>
                        <div>
                            <Label label={endTimeLabel} />
                            <TimePicker
                                format={formatDate}
                                value={
                                    item.slot_hours_end
                                        ? moment(
                                              item.slot_hours_end,
                                              formatDate,
                                          )
                                        : null
                                }
                                onChange={(time, timeString) =>
                                    updateItem(
                                        index,
                                        'slot_hours_end',
                                        moment(timeString, formatDate),
                                    )
                                }
                                minTime={moment(
                                    item.slot_hours_start,
                                    formatDate,
                                )}
                                disabledTime={() => ({
                                    disabledHours: () => [
                                        ...Array(
                                            moment(
                                                item.slot_hours_start,
                                                formatDate,
                                            ).hour(),
                                        ).keys(),
                                    ], // Disable hours before minTime
                                    disabledMinutes: (selectedHour) =>
                                        selectedHour ===
                                        moment(
                                            item.slot_hours_start,
                                            formatDate,
                                        ).hour()
                                            ? [
                                                  ...Array(
                                                      moment(
                                                          item.slot_hours_start,
                                                          formatDate,
                                                      ).minute(),
                                                  ).keys(),
                                              ]
                                            : [],
                                })}
                                use12Hours={false}
                                disabled={!item.slot_hours_start || disabled}
                            />
                        </div>
                        {index === 0 ? (
                            <Button
                                color='outlined'
                                className={`flex items-center justify-center h-10${disabled ? ' cursor-not-allowed opacity-25' : ''}`}
                                onClick={addItem}
                            >
                                <CgAddR fontSize={20} />
                            </Button>
                        ) : (
                            <Button
                                color='outlined'
                                className={`flex items-center justify-center h-10${disabled ? ' cursor-not-allowed opacity-25' : ''}`}
                                onClick={() => removeItem(index)}
                            >
                                <FaRegMinusSquare fontSize={20} />
                            </Button>
                        )}
                    </div>
                    {/* Show error message under inputs */}
                    {item.error && (
                        <p className='text-red-500 text-sm'>{item.error}</p>
                    )}
                </div>
            ))}
        </>
    );
};

export default RepeaterTime;
