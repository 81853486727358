import React from 'react';
import Spinner from '../spinner/Spinner';

export default function AlertMessage({
    statusMessage,
    defaultMessage,
    loading,
}) {
    function setStyleAlert(color) {
        const colorList = ['success', 'error'];
        let tailwindColor;
        let style = 'border px-4 py-3 rounded relative mb-5 mt-2.5';

        if (colorList.includes(color)) {
            if (color === 'success') {
                tailwindColor =
                    'bg-green-100 border-green-400 text-green-700 border-solid';
            } else {
                tailwindColor =
                    'bg-red-100 border-red-400 text-red-700 border-solid';
            }
            style = style + ' ' + tailwindColor;
        }

        return style;
    }

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                statusMessage !== null && (
                    <div class={`${setStyleAlert(statusMessage)}`}>
                        <span>{defaultMessage}</span>
                    </div>
                )
            )}
        </>
    );
}
