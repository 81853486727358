import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { amountFormat } from '../../modUtils/functions/numberFormat';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

export default function StatisticsGlobal({
    dataStatisticGlobalBySites,
    dataStatisticGlobalByDay,
}) {
    // État pour stocker les couleurs
    const [primaryColor, setPrimaryColor] = useState('#338EF5');
    const [secondaryColor, setSecondaryColor] = useState('#51BEFF');
    const [accentColor, setAccentColor] = useState('#36C31D');
    const [mutedColor, setMutedColor] = useState('#D1D9E4');

    useEffect(() => {
        // Récupérer les couleurs CSS du :root
        const styles = getComputedStyle(document.documentElement);
        setPrimaryColor(styles.getPropertyValue('--primary-color').trim());
        setSecondaryColor(styles.getPropertyValue('--secondary-color').trim());
        setAccentColor(styles.getPropertyValue('--accent-color').trim());
        setMutedColor(styles.getPropertyValue('--muted-color').trim());
    }, []);

    //* Options et données pour les graphiques
    const barOptions1 = {
        responsive: true,
        plugins: {
            legend: { position: 'bottom' },
        },
    };

    const barLabels1 = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
    ];
    const barDatasetGlobal1 = [10, 20, 30, 40, 50, 60, 70];
    const barDatasetGlobal2 = [70, 60, 50, 40, 30, 10];

    const barData1 = {
        labels: barLabels1,
        datasets: [
            {
                label: 'Période prédéfinie',
                data: barDatasetGlobal1,
                backgroundColor: primaryColor,
            },
            {
                label: 'Période prédéfinie -1',
                data: barDatasetGlobal2,
                backgroundColor: mutedColor,
            },
        ],
    };

    //* Répartition chiffre d'affaire par site
    const [statisticGlobalBySitesLabels, setStatisticGlobalBySitesLabels] =
        useState([]);
    const [statisticGlobalBySitesData, setStatisticGlobalBySitesData] =
        useState([]);

    useEffect(() => {
        if (dataStatisticGlobalBySites) {
            const labels = [];
            const data = [];
            [...dataStatisticGlobalBySites].forEach((item) => {
                labels.push(item.site);
                data.push(item.total_global);
            });
            setStatisticGlobalBySitesLabels(labels);
            setStatisticGlobalBySitesData(data);
        }
    }, [dataStatisticGlobalBySites]);

    const piedata = {
        labels: statisticGlobalBySitesLabels,
        datasets: [
            {
                data: statisticGlobalBySitesData,
                backgroundColor: [primaryColor, secondaryColor, accentColor],
                borderColor: [primaryColor, secondaryColor, accentColor],
                borderWidth: 1,
            },
        ],
    };

    //* Activité moyenne par jour
    const [statisticGlobalByDayLabels, setStatisticGlobalByDayLabels] =
        useState([]);
    const [statisticGlobalByDayData, setStatisticGlobalByDayData] = useState(
        [],
    );

    useEffect(() => {
        if (dataStatisticGlobalByDay) {
            const labels = [];
            const data = [];
            [...dataStatisticGlobalByDay].forEach((item) => {
                labels.push(item.day);
                data.push(item.total);
            });
            setStatisticGlobalByDayLabels(labels);
            setStatisticGlobalByDayData(data);
        }
    }, [dataStatisticGlobalByDay]);

    const barData2 = {
        labels: statisticGlobalByDayLabels,
        datasets: [
            {
                data: statisticGlobalByDayData,
                backgroundColor: [primaryColor],
                borderColor: [accentColor],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div>
            <div className='uppercase text-[17px] leading-[24px] text-black font-bold m-[20px] text-center'>
                STATISTIQUE GLOBALE
            </div>
            <div className='flex-display flex-row justify-content-between'>
                <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[49%]'>
                    <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
                        CHIFFRE D'AFFAIRE GLOBAL
                    </div>
                    <Bar options={barOptions1} data={barData1} />
                </div>
                <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[49%]'>
                    <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
                        REPARITION CHIFFRE D'AFFAIRE PAR SITE
                    </div>
                    <Pie options={barOptions1} data={piedata} />
                </div>
            </div>

            <div className='flex-display flex-row justify-content-between '>
                <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[49%]'>
                    <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
                        ACTIVITE MOYENNE PAR JOUR
                    </div>
                    <Bar options={barOptions1} data={barData2} />
                </div>
            </div>
        </div>
    );
}
