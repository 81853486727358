import React from 'react';

export default function Option({ onClick, className = {}, ...props }) {
    return (
        <div
            className={`
                w-full text-left bg-background-color hover:bg-primary-color text-primary-color
                hover:text-primary-foreground-color px-4 py-2 rounded-md text-base
                ${className}`}
            onClick={onClick}
        >
            {props.children}
        </div>
    );
}
