import React from 'react';
import { InputNumber, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/numberFormat';
import { Label } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import MarkerRequired from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/MarkerRequired';

export default function InputAmountForm({
    label = 'inputAmountForm.amount',
    className = '',
    formRequired = true,
    messageRequired,
}) {
    const { t } = useTranslation();

    return (
        <div className={className}>
            {formRequired && <MarkerRequired />}

            <Label label={label} />

            <Form.Item
                name='amount'
                rules={[
                    {
                        required: formRequired,
                        message: messageRequired
                            ? messageRequired
                            : t('inputAmountForm.amountIsRequired'),
                    },
                    {
                        validator: (_, value) =>
                            value >= 0.01 && value <= 1000
                                ? Promise.resolve()
                                : Promise.reject(
                                      t(
                                          'inputAmountForm.theAmountMustBeBetween',
                                          {
                                              min: 0.01,
                                              max: 1000,
                                          },
                                      ),
                                  ),
                    },
                ]}
            >
                <InputNumber
                    min={0.01}
                    max={1000}
                    step={0.01}
                    precision={2}
                    className='w-full'
                    addonAfter={getCurrencySymbol()}
                    stringMode
                    formatter={(value) => value?.toString().replace('.', ',')}
                    parser={(value) => value?.replace(',', '.')}
                />
            </Form.Item>
        </div>
    );
}
