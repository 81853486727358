import React, { useState } from 'react';
import moment from 'moment';
import { Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import CkForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/CkForm';
import InputDateForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputDateForm';
import InputEmailForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputEmailForm';
import InputAmountForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputAmountForm';
import { support_pre_payment_create } from 'ck_commun/src/app/modSupportPrepayment/slices/supportPrepaymentSlices';
import TextAreaForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/TextAreaForm';

/**
 * Formulaire de remboursement pour l'équipement
 * Utilisé dans l'onglet "REMBOURSEMENT" de la modal "DÉPANNAGE"
 *
 * Pour le retrouver plus facilement :
 * URL d'accès : https://dev.v3.ck-wash.fr/manager/equipment/sites-equipments
 * Étapes pour accéder :
 * 1. Ouvrir la modal "DÉPANNAGE"
 * 2. Aller dans l'onglet "REMBOURSEMENT"
 *
 * Ce formulaire est utilisé pour gérer les remboursements d'équipements.
 */
export default function FormRepay({ equipment }) {
    const currentDate = moment();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const oneMonthLaterDate = moment().add(1, 'month');
    const { groupId } = useSelector((state) => state.manager);

    const createSupportPrePayment = async (values) => {
        try {
            setLoading(true);
            await dispatch(
                support_pre_payment_create({
                    groupId,
                    email: values.email,
                    amount: parseFloat(values.amount),
                    dateExpiration: moment(values.dateExpiration).unix(),
                    incidentDate: moment(values.incidentDate).unix(),
                    incidentReason: values.incidentReason,
                    incidentEquipment: equipment?.id,
                }),
            ).unwrap();
            setStatus('success');
        } catch (error) {
            setStatus('error');
        } finally {
            setLoading(false);
            form.resetFields();
        }
    };

    return (
        <CkForm
            form={form}
            status={status}
            loading={loading}
            onFinish={createSupportPrePayment}
            className='p-[5%] overflow-auto h-full'
            initialValues={{
                incidentDate: currentDate,
                dateExpiration: oneMonthLaterDate,
            }}
        >
            <InputEmailForm />

            {/* TODO Activer ces options lorsque le backend est prêt */}
            <div className='flex flex-col mt-2'>
                <Label label='reimbursementForm.typeOfReimbursement' />
                <Radio checked disabled>
                    {t('reimbursementForm.QRcode')}
                </Radio>
            </div>

            {/* TODO Activer ces options lorsque le backend est prêt */}
            <div className='flex flex-col mt-2'>
                <Label label='reimbursementForm.typeOfShipment' />
                <Radio checked disabled>
                    {t('reimbursementForm.email')}
                </Radio>
            </div>

            <TextAreaForm
                label='reimbursementForm.reason'
                name='incidentReason'
                className='mt-2'
            />

            <InputAmountForm
                label='reimbursementForm.amountToCredit'
                className='mt-2'
            />

            <InputDateForm
                form={form}
                Datedefault={currentDate}
                label='reimbursementForm.incidentDate'
                name='incidentDate'
                className='mt-2'
                dateIsRequired={t('reimbursementForm.incidentDateIsRequired')}
            />

            <InputDateForm
                form={form}
                Datedefault={oneMonthLaterDate}
                label='reimbursementForm.expirationDate'
                name='dateExpiration'
                className='mt-2'
                dateIsRequired={t('reimbursementForm.expirationDateIsRequired')}
            />
        </CkForm>
    );
}
