import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import SiteUpdateModel from '../siteUpdateModal/SiteUpdateModal';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from '../../../modUtils/functions/handlePermissions';
import CKToolTip from '../../../modUtils/components/CKToolTip/CKToolTip';
import Button from '../../../modUtils/components/buttons/Button';
import ButtonDisabled from '../../../modUtils/components/buttons/ButtonDisabled';
import AdminLink from '../../../modUtils/components/adminLink/AdminLink';
import { useTranslation } from 'react-i18next';

export default function SiteManageModal({
    site,
    modalVisible,
    handleModal,
    closeModal,
    mapModal,
}) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_cumulative:show_statistic',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_cumulative:show_statistic',
        groupPermissions,
    );

    const modalFooter = (
        <div className='flex-display flex-column' style={{ gap: '10px' }}>
            <div className='flex-display flex-row' style={{ gap: '10px' }}>
                <SiteUpdateModel
                    closeModal={closeModal}
                    site={site}
                    mapModal={mapModal}
                />
                {verifyPermission && verifyGroupPermission ? (
                    <Button
                        onClick={() => navigate(`/activity/ca`)}
                        color='primary'
                        className='h-10 w-full mx-[5px]'
                        size='sm'
                    >
                        ACTIVITÉ/CA
                    </Button>
                ) : (
                    <ButtonDisabled
                        onClick={
                            verifyPermission
                                ? () => navigate(`/activity/ca`)
                                : null
                        }
                        className='h-10 w-full whitespace-nowrap mx-[5px]'
                        size='sm'
                    >
                        ACTIVITÉ/CA
                    </ButtonDisabled>
                )}
            </div>
            {/* TODO : a ajouter quand les pages relevé et stats seront prêtes */}
            {/* <div className="flex-display flex-row" style={{ gap: "10px" }}>
        <Button type="primary" block onClick={handleModal}>
          RELEVÉS
        </Button>
        <Button type="primary" block onClick={handleModal}>
          STATISTIQUES
        </Button>
      </div> */}
        </div>
    );

    return (
        <div className='w-fit' onClick={(e) => e.stopPropagation()}>
            {!mapModal && (
                <div
                    className='whitespace-nowrap mr-8 min-w-64 w-64 lg:w-64 xl:w-72 2xl:w-80 text-neutral-100 font-bold text-left text-lg text-ellipsis cursor-pointer'
                    onClick={handleModal}
                >
                    <CKToolTip
                        placement='topLeft'
                        trigger='hover'
                        content={site.name}
                    >
                        <span>{site.name}</span>
                    </CKToolTip>
                </div>
            )}

            <Modal
                title={t('siteManageModal.modalTitle')}
                isOpen={modalVisible}
                onClose={closeModal}
                footer={modalFooter}
            >
                <span className='text-primary-color text-muted absolute top-4 left-4 '>
                    id: {site.id}
                </span>

                <AdminLink
                    type='button'
                    label={t('siteManageModal.adminLinkSite')}
                    adminPath={`mod_manager/site/${site.id}/change/`}
                    className='whitespace-nowrap text-xs leading-3 absolute top-8'
                />

                <div
                    className={`text-[#013D7B] p-[20px] mb-[10px] flex flex-col justify-around mt-8
                    border border-solid border-secondary-color rounded-[10px] bg-primary-light-color
                    `}
                >
                    <span>{site?.name}</span>
                    <span>{site?.description}</span>
                    {(site?.location_address?.street_number ||
                        site?.location_address?.street) && (
                        <span>
                            {`${
                                site?.location_address?.street_number
                                    ? site?.location_address?.street_number
                                    : ''
                            } ${
                                site?.location_address?.street
                                    ? site?.location_address?.street
                                    : ''
                            }`}
                        </span>
                    )}
                    {(site?.location_address?.zipcode ||
                        site?.location_address?.city) && (
                        <span>
                            {site?.location_address?.zipcode
                                ? site?.location_address?.zipcode
                                : ''}{' '}
                            {site?.location_address?.city
                                ? site?.location_address?.city
                                : ''}
                        </span>
                    )}
                </div>
            </Modal>
        </div>
    );
}
