import React, { useState } from 'react';
import moment from 'moment';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import MarkerRequired from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/MarkerRequired';
import {
    DatePicker,
    Label,
} from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';

export default function InputDateForm({
    name = 'date',
    form,
    label,
    className = '',
    formatDate = 'DD/MM/YYYY',
    Datedefault = moment(),
    formRequired = true,
    dateIsRequired,
}) {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(Datedefault);

    const handleDateChange = (date, fieldName) => {
        const momentDate = moment(date, formatDate, true);
        setSelectedDate(momentDate);
        form.setFieldsValue({ [fieldName]: momentDate });
    };

    return (
        <div className={className}>
            <Form.Item
                name={name}
                rules={[
                    {
                        required: formRequired,
                        message: dateIsRequired
                            ? dateIsRequired
                            : t('inputDateForm.dateIsRequired'),
                    },
                ]}
            >
                <DatePicker
                    label={
                        label && (
                            <>
                                {formRequired && <MarkerRequired />}
                                <Label label={label} className='text-black' />
                            </>
                        )
                    }
                    selectedDate={selectedDate}
                    onDateChange={(date) => handleDateChange(date, name)}
                    value={form.getFieldValue(name)}
                    format={formatDate}
                />
            </Form.Item>
        </div>
    );
}
