import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    httpDelete,
    httpGet,
    httpPost,
    httpPut,
} from '../../modUtils/apis/httpConfig';
import { removeNullFields } from '../../modUtils/functions/removeNulFields';

// retourner la liste des promotions
export const get_promotions_paginated = createAsyncThunk(
    'mod_promotion/promotions_by_group_paginated_get',
    async (values) => {
        const response = await httpGet(
            `mod_promotion/groups/${values.groupId}/promotions/`,
            {
                params: {
                    promotion_type: values.promotion_type,
                    date_end: values.date_end,
                    limit: values.limit,
                    offset: values.offset,
                },
            },
        );
        return response.data;
    },
);

// création d'une promotion
export const create_promotion = createAsyncThunk(
    'mod_promotion/promotions_by_group_create',
    async (values) => {
        const response = await httpPost(
            `mod_promotion/groups/${values.groupId}/promotions/`,
            removeNullFields({
                name: values.promotionName,
                description: values.description,
                promotion_type: values.typePromo,
                amount: values.amountUsePromo,
                amount_promo_type: values.discountAmount,
                criteria_obtaining: removeNullFields({
                    date_start: values.startDateOfferObtainPromo,
                    date_end: values.endDateOfferObtainPromo,
                    amount_min: values.minObtainPromo,
                    amount_max: values.maxObtainPromo,
                    site_ids: values.sitesObtainPromo,
                    equipment_types: values.equipementsTypeObtainPromo,
                    count_distribution_max: values.maximumPrintingObtainPromo,
                    criteria_schedules: values.constraintObtainPromo,
                }),
                criteria_usage: {
                    date_start: values.startDateOfferUsePromo,
                    date_end: values.endDateOfferUsePromo,
                    amount_min: values.minUsePromo,
                    amount_max: values.maxUsePromo,
                    site_ids: values.sitesUsePromo,
                    equipment_types: values.equipementsTypeUsePromo,
                    selection_ids: values.idsUsePromo?.split(',').map(Number),
                    criteria_schedules: values.constraintUsePromo,
                    count_usage_max:
                        values.maximumPrintingUsePromo === 'illimited'
                            ? null
                            : values.maximumPrintingUsePromo === '1'
                              ? 1
                              : values.maximumPrintingUsePromo,
                    lifetime_start_days: values.startDayValidityUsePromo,
                    lifetime: values.endDayValidityUsePromo,
                },
                ticket_template_sms: values.ticket_template_sms,
                ticket_template_email: values.ticket_template_email,
                ticket_template_paper: values.ticket_template_paper,
            }),
        );
        return response.data;
    },
);

// modification d'une promotion
export const update_promotion = createAsyncThunk(
    'mod_promotion/promotion_detail_by_group_update',
    async (values) => {
        const response = await httpPut(
            `mod_promotion/groups/${values.groupId}/promotion/${values.promotionId}/`,
            {
                name: values.promotionName,
                description: values.description,
                promotion_type: values.typePromo,
                amount: values.amountUsePromo,
                amount_promo_type: values.discountAmount,
                criteria_obtaining: {
                    date_start: values.startDateOfferObtainPromo,
                    date_end: values.endDateOfferObtainPromo,
                    amount_min: values.minObtainPromo,
                    amount_max: values.maxObtainPromo,
                    site_ids: values.sitesObtainPromo,
                    equipment_types: values.equipementsTypeObtainPromo,
                    count_distribution_max: values.maximumPrintingObtainPromo,
                },
                criteria_usage: {
                    date_start: values.startDateOfferUsePromo,
                    date_end: values.endDateOfferUsePromo,
                    amount_min: values.minUsePromo,
                    amount_max: values.maxUsePromo,
                    site_ids: values.sitesUsePromo,
                    equipment_types: values.equipementsTypeUsePromo,
                    selection_ids: values.idsUsePromo?.split(',').map(Number),
                    count_usage_max: values.maximumPrintingUsePromo,
                    lifetime_start_days: values.startDayValidityUsePromo,
                    lifetime: values.endDayValidityUsePromo,
                },
                ticket_template_sms: {
                    data: null,
                    props: {
                        module_name: null,
                        template_name: null,
                        variables_available: [],
                        interpretor: {},
                    },
                },
                ticket_template_email: {
                    data: null,
                    props: {},
                },
                ticket_template_paper: {
                    data: null,
                    props: {},
                },
            },
        );
        return response.data;
    },
);

// retourner les details d'une promotion
export const get_promotion_detail = createAsyncThunk(
    'mod_promotion/promotion_detail_by_group_get',
    async (values) => {
        const response = await httpGet(
            `mod_promotion/groups/${values.groupId}/promotion/${values.promotionId}/`,
        );
        return response.data;
    },
);

// retourner la liste de tous les supports
export const get_supports = createAsyncThunk(
    'mod_promotion/support_promo_by_group_paginated_get',
    async (values) => {
        const response = await httpGet(
            `mod_promotion/groups/${values.groupId}/supports_promo/`,
            {
                params: {
                    limit: values.limit,
                    offset: values.offset,
                    promotion: values.promotion,
                    date_validity_start: values.date_validity_start,
                    date_validity_end: values.date_validity_end,
                    qrcode_number: values.qrcode_number,
                    status: values.status,
                },
            },
        );
        return response.data;
    },
);

// retourner les supports d'une promotion
export const get_promotion_supports = createAsyncThunk(
    'mod_promotion/support_promo_by_promotion_paginated_get',
    async (values) => {
        const response = await httpGet(
            `mod_promotion/groups/${values.groupId}/promotion/${values.promotion}/supports_promo/`,
            {
                params: {
                    limit: values.limit,
                    offset: values.offset,
                    promotion: values.promotion,
                    date_validity_start: values.date_validity_start,
                    date_validity_end: values.date_validity_end,
                    qrcode_number: values.qrcode_number,
                    status: values.status,
                },
            },
        );
        return response.data;
    },
);

// créer suuports
export const create_promotion_supports = createAsyncThunk(
    'mod_promotion/support_promo_by_promotion_create',
    async (values) => {
        const response = await httpPost(
            `mod_promotion/groups/${values.groupId}/promotion/${values.promotionId}/supports_promo/`,
            {
                amount_to_generate: values.amount_to_generate,
            },
        );
        return response.data;
    },
);

// Clotuer suuports
export const close_promotion_supports = createAsyncThunk(
    'mod_promotion/support_promo_by_promotion_delete',
    async (values) => {
        const response = await httpDelete(
            `mod_promotion/groups/${values.groupId}/promotion/${values.promotionId}/supports_promo/`,
            {
                data: {
                    close_count: values.close_count,
                    date_validity_start: values.startDate,
                    date_validity_end: values.endDate,
                },
            },
        );
        return response.data;
    },
);

// Clotuer suuport
export const close_promotion_support = createAsyncThunk(
    'mod_promotion/support_promo_detail_by_promotion_delete',
    async (values) => {
        const response = await httpDelete(
            `mod_promotion/groups/${values.groupId}/promotion/${values.promotionId}/support_promo/${values.supportId}/`,
        );
        return response.data;
    },
);

// Detail suuport
export const get_promotion_support_detail = createAsyncThunk(
    'mod_promotion/support_promo_detail_by_promotion_get',
    async (values) => {
        const response = await httpGet(
            `mod_promotion/groups/${values.groupId}/promotion/${values.promotionId}/support_promo/${values.supportId}/`,
        );
        return response.data;
    },
);

// Modifier suuport
export const update_promotion_support_detail = createAsyncThunk(
    'mod_promotion/support_promo_detail_by_promotion_update',
    async (values) => {
        const response = await httpPut(
            `mod_promotion/groups/${values.groupId}/promotion/${values.promotionId}/support_promo/${values.supportId}/`,
            {
                status: values.status,
            },
        );
        return response.data;
    },
);

const promotionSlice = createSlice({
    name: 'promotion',
    initialState: {
        loading: false,
        promotions: null,
        promotionsCount: null,
        loadingDetail: false,
        promotionDetail: null,
        loadingSupport: false,
        supports: null,
        supportsCount: null,
        loadingSupportDetail: false,
        supportDetail: null,
    },
    extraReducers: (builder) => {
        builder
            /* get_promotion_paginated */
            .addCase(get_promotions_paginated.pending, (state, action) => {
                state.promotions = [];
                state.loading = true;
            })
            .addCase(get_promotions_paginated.fulfilled, (state, action) => {
                state.loading = false;
                state.promotions = action.payload.results;
                state.promotionsCount = action.payload.count;
            })
            .addCase(get_promotions_paginated.rejected, (state, action) => {
                state.loading = false;
            })
            /* get_promotion_detail */
            .addCase(get_promotion_detail.pending, (state, action) => {
                state.loadingDetail = true;
            })
            .addCase(get_promotion_detail.fulfilled, (state, action) => {
                state.loadingDetail = false;
                state.promotionDetail = action.payload;
            })
            .addCase(get_promotion_detail.rejected, (state, action) => {
                state.loadingDetail = false;
            })
            /* get_supports */
            .addCase(get_supports.pending, (state, action) => {
                state.loadingSupport = true;
            })
            .addCase(get_supports.fulfilled, (state, action) => {
                state.loadingSupport = false;
                state.supports = action.payload.results;
                state.supportsCount = action.payload.count;
            })
            .addCase(get_supports.rejected, (state, action) => {
                state.loadingSupport = false;
            })
            /* get_promotion_supports */
            .addCase(get_promotion_supports.pending, (state, action) => {
                state.loadingSupport = true;
            })
            .addCase(get_promotion_supports.fulfilled, (state, action) => {
                state.loadingSupport = false;
                state.supports = action.payload.results;
                state.supportsCount = action.payload.count;
            })
            .addCase(get_promotion_supports.rejected, (state, action) => {
                state.loadingSupport = false;
            })
            /* get_support_detail */
            .addCase(get_promotion_support_detail.pending, (state, action) => {
                state.loadingSupportDetail = true;
            })
            .addCase(
                get_promotion_support_detail.fulfilled,
                (state, action) => {
                    state.loadingSupportDetail = false;
                    state.supportDetail = action.payload;
                },
            )
            .addCase(get_promotion_support_detail.rejected, (state, action) => {
                state.loadingSupportDetail = false;
            });
    },
});

export default promotionSlice.reducer;
