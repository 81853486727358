import React from 'react';
import { Label } from '../componentsLibrary/componentsLibrary';
import { ReactComponent as ChevronDownSvg } from '../../assets/images/chevron-down.svg';

export default function OptionCollapsed({
    onClick,
    optionsHidden,
    searchValue,
    label,
    width,
}) {
    const inputColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--input-color')
        .trim();

    return (
        <>
            {label && <Label label={label} className='h-6 text-left' />}

            <div
                className={`
                bg-card-color border border-solid border-input-color
                text-primary-color cursor-pointer px-4 py-2 rounded-md text-base
                max-w-[120px] ${width ? width : 'min-w-52'}
            `}
                onClick={() => onClick()}
            >
                <div className='flex flex-row items-center justify-between truncate'>
                    <div className='mr-2.5 whitespace-nowrap overflow-hidden text-ellipsis truncate w-full text-left'>
                        {searchValue}
                    </div>

                    <div
                        className={`${optionsHidden && 'rotate-180 relative top-[-4px] left-[-8px]'} w-5`}
                    >
                        <ChevronDownSvg color={inputColor} />
                    </div>
                </div>
            </div>
        </>
    );
}
