import { FaRegTrashAlt } from 'react-icons/fa';
import { HiOutlinePencil } from 'react-icons/hi2';

export const days = (t) => [
    {
        value: 'schedule_monday',
        label: t('createPromotionModal.stepperCreatePromotion.transMonday'),
    },
    {
        value: 'schedule_tuesday',
        label: t('createPromotionModal.stepperCreatePromotion.transTuesday'),
    },
    {
        value: 'schedule_wednesday',
        label: t('createPromotionModal.stepperCreatePromotion.transWednesday'),
    },
    {
        value: 'schedule_thursday',
        label: t('createPromotionModal.stepperCreatePromotion.transThursday'),
    },
    {
        value: 'schedule_friday',
        label: t('createPromotionModal.stepperCreatePromotion.transFriday'),
    },
    {
        value: 'schedule_saturday',
        label: t('createPromotionModal.stepperCreatePromotion.transSaturday'),
    },
    {
        value: 'schedule_sunday',
        label: t('createPromotionModal.stepperCreatePromotion.transSunday'),
    },
];

export const columns = (t, handleEditSchedule, deleteSchedule) => [
    {
        title: t('createPromotionModal.stepperCreatePromotion.table.transDays'),
        dataIndex: 'days',
        key: 'days',
        width: '50%',
        render: (text, record) => {
            const selectedDays = days(t)
                .filter((day) => record[day.value]) // Keep only the days where the schedule is true
                .map((day) => day.label.substring(0, 3));
            return (
                <div className='px-[5px]'>
                    <span className='text-xs text-wrap'>
                        {selectedDays.join(', ')}
                    </span>
                </div>
            );
        },
    },
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transScheduleFrom',
        ),
        dataIndex: 'schedules',
        key: 'schedules',
        td: { padding: 0 },
        render: (text, record) => (
            <div>
                {record.time_slots.map((schedule, index) => (
                    <div
                        className={`py-1${index !== record.time_slots.length - 1 && ' border-b border-t-0 border-x-0 border-solid border-b-[#D1D8DD]'}`}
                        key={index}
                    >
                        {schedule.slot_hours_start}
                    </div>
                ))}
            </div>
        ),
    },
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transScheduleTo',
        ),
        dataIndex: 'schedules',
        key: 'schedules',
        render: (text, record) => (
            <div>
                {record.time_slots.map((schedule, index) => (
                    <div
                        className={`py-1${index !== record.time_slots.length - 1 && ' border-b border-t-0 border-x-0 border-solid border-b-[#D1D8DD]'}`}
                        key={index}
                    >
                        {schedule.slot_hours_start}
                    </div>
                ))}
            </div>
        ),
    },
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transActions',
        ),
        dataIndex: '',
        key: '',
        render: (text, record) => (
            <div className='actions-table flex items-center justify-around'>
                <HiOutlinePencil
                    className='cursor-pointer'
                    fontSize={15}
                    onClick={() => handleEditSchedule(record)}
                />
                <FaRegTrashAlt
                    className='cursor-pointer'
                    color='#ff0000'
                    fontSize={15}
                    onClick={() => deleteSchedule(record)}
                />
            </div>
        ),
    },
];

export const variable_array = (t) => [
    {
        key: t('createPromotionModal.stepperCreatePromotion.transQrCode'),
        variable: 'QRCODE.CMD',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transBarCode'),
        variable: 'QRCODE.VALUE',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.transNamePromotion',
        ),
        variable: 'NAME',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transDescription'),
        variable: 'DESCRIPTION',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.showCard.transTypePromotion',
        ),
        variable: 'PROMOTION_TYPE',
    },

    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.transValidityStart',
        ),
        variable: 'VALIDITY_START',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transValidityEnd'),
        variable: 'VALIDITY_END',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transSite'),
        variable: 'SITES',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.transEquipmentTypeVariable',
        ),
        variable: 'EQUIPMENTS.TYPE',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transUseMax'),
        variable: 'USAGEMAX',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.transLifeTimeStart',
        ),
        variable: 'LIFETIME_START_DAYS',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transLifeTime'),
        variable: 'LIFETIME',
    },
];

export const style_array = (t) => [
    {
        key: t('createPromotionModal.stepperCreatePromotion.transLeftVariable'),
        variable: 'LEFT',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.trabnsRightVariable',
        ),
        variable: 'RIGHT',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.transCenterVariable',
        ),
        variable: 'CENTER',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transNormalSize'),
        variable: 'SIZE_NORMAL',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transDoubleSize'),
        variable: 'SIZE_DOUBLE',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transBoldVariable'),
        variable: 'BOLD',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transItalic'),
        variable: 'ITALIC',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.transSkipLineVariable',
        ),
        variable: 'LF',
    },
];

export const optionsStatus = (t) => [
    {
        value: null,
        label: t('modalViewTickets.filterSelectOptions.transAll'),
    },
    {
        value: 0,
        label: t('modalViewTickets.filterSelectOptions.transInStock'),
    },
    {
        value: 1,
        label: t('modalViewTickets.filterSelectOptions.transDistributed'),
    },
    {
        value: 2,
        label: t('modalViewTickets.filterSelectOptions.transInUse'),
    },
    {
        value: 3,
        label: t('modalViewTickets.filterSelectOptions.transClosed'),
    },
];
