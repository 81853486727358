import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../buttons/submitButton/SubmitButton';
import Button from '../../buttons/Button';

const Modal = ({
    isOpen,
    title,
    width = 600,
    height,
    zIndex = 1000,
    onOk,
    onOkButtonDisabled = false,
    onClose,
    onCancel,
    okText = 'Submit',
    cancelText = 'Cancel',
    footer,
    className,
    style = {},
    ...props
}) => {
    const modalRoot = document.getElementById('root');
    const { t } = useTranslation();

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && isOpen) {
                onClose();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [isOpen, onClose]);

    if (!modalRoot) {
        console.error('Modal root element not found.');
        return null;
    }

    const handleOk = () => onOk && onOk();
    const handleCancel = () => {
        onCancel && onCancel();
        onClose();
    };

    const modalContent = (
        <div
            className={`fixed z-[8888] top-0 left-0 w-full h-full items-center justify-center ${
                isOpen ? 'flex' : 'hidden'
            } ${className}`}
            style={{ zIndex }}
        >
            <div
                className='absolute top-0 left-0 w-full h-full bg-black opacity-75'
                style={style.modalOverlay}
                onClick={onClose}
            ></div>
            <div
                id='modal-content'
                className='relative bg-card-color p-[20px_35px] shadow-[0px_3px_6px_#999999] rounded-[10px] max-w-[95%] max-h-[95%] overflow-x-hidden overflow-y-auto flex flex-col justify-between'
                style={{ width, height, ...style.modalContent }}
                role='dialog'
                tabIndex='-1'
            >
                <div>
                    <div className='text-card-foreground-color font-bold text-[24px] leading-[32px] break-words uppercase text-center mb-[25px]'>
                        {title}
                    </div>
                    <div
                        className='absolute text-primary-color top-[-0.5rem] right-[0.5rem] bg-transparent border-0 outline-none text-[40px] cursor-pointer'
                        onClick={onClose}
                    >
                        &times;
                    </div>
                    <div style={style.modalBody}>{props.children}</div>
                </div>
                <div className='mt-[10px]' style={style.modalFooter}>
                    {footer}
                    <div
                        className='flex flex-row justify-center items-stretch'
                        style={{ marginTop: '10px', gap: '10px' }}
                    >
                        {onCancel && (
                            <Button color='error' onClick={handleCancel}>
                                {t('common.transButtonCancel')}
                            </Button>
                        )}
                        {onOk && (
                            <SubmitButton
                                label={t('common.transButtonSubmit')}
                                onClick={handleOk}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    return createPortal(modalContent, modalRoot);
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zIndex: PropTypes.number,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    footer: PropTypes.node,
    style: PropTypes.object,
};

export default Modal;
