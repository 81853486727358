import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/dateFormat';
import SubmitButton from 'ck_commun/src/app/modUtils/components/buttons/submitButton/SubmitButton';
import FilterByEquipmentType from 'ck_commun/src/app/modUtils/components/filters/FilterByEquipmentType';
import FilterBySearchSite from 'ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite';
import FilterByDate from 'ck_commun/src/app/modUtils/components/filters/FilterByDate';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './ActivityMarketing.scss';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

export const lineOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
    },
    elements: {
        line: {
            tension: 0.5,
        },
    },
};
const lineLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
];
const lineDatasetOp1 = [10, 200, 30, 450, 150, 60, 70];
const lineDatasetOp2 = [50, 50, 70, 150, 500, 100];
const lineDatasetOp3 = [100, 200, 300, 450, 250, 60, 90];
export const lineData1 = {
    labels: lineLabels,
    datasets: [
        {
            label: 'Opération 1',
            data: lineDatasetOp1,
            borderColor: '#004CFF',
            backgroundColor: '#004CFF',
        },
        {
            label: 'Opération 2',
            data: lineDatasetOp2,
            borderColor: '#01A73E',
            backgroundColor: '#01A73E',
        },
        {
            label: 'Opération 3',
            data: lineDatasetOp3,
            borderColor: '#FFC857',
            backgroundColor: '#FFC857',
        },
    ],
};
export default function ActivityMarketing() {
    const { t } = useTranslation();
    const [siteList, setSiteList] = useState();
    const [equipmentTypeList, setEquipmentTypeList] = useState();
    function handleSiteValueChange(selectedList) {
        setSiteList(selectedList?.length > 0 ? selectedList : null);
    }
    const handleEquipmentType = (selectedList) => {
        setEquipmentTypeList(selectedList?.length > 0 ? selectedList : null);
    };
    const onExecuteSubmitBtn = () => {
        console.log('btn clicked');
    };
    const logs = [
        {
            RES: true,
            DATE: '784397627',
            MKID: 'opération vacances de noel',
            SOURCE: '0',
            TARGET: '1',
            MONTANT: '3',
        },
        {
            RES: false,
            DATE: '784397627',
            MKID: 'vente flash',
            SOURCE: '0',
            TARGET: '1',
            MONTANT: '10',
        },
        {
            RES: true,
            DATE: '784397627',
            MKID: 'opération vacances de noel',
            SOURCE: '0',
            TARGET: '1',
            MONTANT: '100',
        },
    ];
    const columns = [
        {
            title: t('table.tabColRes'),
            dataIndex: 'RES',
            key: 'RES',
            render: (text) => (
                <span>
                    {text ? (
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/badgesIcons/actionExecuted.png'
                            }
                            alt='exécuté'
                        ></img>
                    ) : (
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/badgesIcons/x.svg'
                            }
                            alt='annulé'
                        ></img>
                    )}
                </span>
            ),
        },
        {
            title: t('table.tabColDate'),
            dataIndex: 'DATE',
            key: 'DATE',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColMkid'),
            dataIndex: 'MKID',
            key: 'MKID',
            align: 'left',
            bold: true,
        },
        {
            title: t('table.tabColSource'),
            dataIndex: 'SOURCE',
            key: 'SOURCE',
            bold: true,
        },
        {
            title: t('table.tabColTarget'),
            dataIndex: 'TARGET',
            key: 'TARGET',
            bold: true,
        },
        {
            title: t('table.tabColAmount'),
            dataIndex: 'MONTANT',
            key: 'MONTANT',
            width: 100,
            className: 'table-column-accent',
            headerClassName: 'table-column-accent',
            render: (text) => <span>{text ? text + ' €' : ''}</span>,
        },
    ];
    return (
        <BuildingPage />
        // <>
        //   <div className="flex-display flex-row align-items-end filters">
        //     <FilterByEquipmentType onValueChange={handleEquipmentType} />
        //     <FilterBySearchSite
        //       onValueChange={handleSiteValueChange}
        //     ></FilterBySearchSite>
        //     <FilterByDate></FilterByDate>

        //     <SubmitButton onClick={() => onExecuteSubmitBtn()} />
        //   </div>

        //   <div className="flex-display flex-row justify-content-center">
        //     <div className="shadow-[0px_3px_6px_#2c282836] border border-[#ececec] rounded-[10px] flex flex-row marketingInfoBox">
        //       <div
        //         className="rounded-[10px_0px_0px_10px] flex justify-center items-centermarketingInfoBoxIcon"
        //         style={{ background: "#1B8ADC" }}
        //       >
        //         <img
        //           src={process.env.PUBLIC_URL + "/img/design/euro.svg"}
        //           alt="euro"
        //         />
        //       </div>
        //       <div className="bg-white rounded-[0px_10px_10px_0px] flex flex-col items-end justify-around p-[14px_19px_12px_5px] marketingInfoBoxContent">
        //         <div className="text-right text-[15px] leading-[21px] text-[#8898aa] uppercase">gain généré </div>
        //         <div className="text-[#4d4f5c] font-bold text-left marketingInfoBoxAmount">
        //           3000 €
        //         </div>
        //       </div>
        //     </div>

        //     <div className="shadow-[0px_3px_6px_#2c282836] border border-[#ececec] rounded-[10px] flex flex-row marketingInfoBox">
        //       <div
        //         className="rounded-[10px_0px_0px_10px] flex justify-center items-center marketingInfoBoxIcon"
        //         style={{ background: "#11CDEF" }}
        //       >
        //         <img
        //           src={process.env.PUBLIC_URL + "/img/design/transformation.svg"}
        //           alt="transformation"
        //         />
        //       </div>
        //       <div className="bg-white rounded-[0px_10px_10px_0px] flex flex-col items-end justify-around p-[14px_19px_12px_5px] marketingInfoBoxContent">
        //         <div className="text-right text-[15px] leading-[21px] text-[#8898aa] uppercase">taux de transformation</div>
        //         <div className="text-[#4d4f5c] font-bold text-left marketingInfoBoxAmount">60%</div>
        //       </div>
        //     </div>
        //   </div>
        //   {/**/}
        //   <div className="flex-display flex-row justify-content-between">
        //     <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[49%]'>
        //       <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>GAINS GENERES</div>
        //       <Line options={lineOptions} data={lineData1} />
        //     </div>
        //     <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[49%]'>
        //       <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>TAUX DE TRANSFORMATION</div>
        //       <Line options={lineOptions} data={lineData1} />
        //     </div>
        //   </div>

        //   {/**/}
        //   <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px]'>
        //     <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
        //       GAINS GENERES ET TAUX DE TRANSFORMATION PAR OPERATION
        //     </div>
        //     <h1> ---- contenu du tableau à modifier -----</h1>
        //     <Table
        //       columns={columns}
        //       dataSource={logs}
        //     />
        //   </div>
        //   {/**/}
        //   <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px]'>
        //     <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>HISTORIQUE DE L'OPERATION MARKETING</div>
        //     <Table
        //       columns={columns}
        //       dataSource={logs}
        //     />
        //   </div>
        // </>
    );
}
