import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet, httpPost } from 'ck_commun/src/app/modUtils/apis/httpConfig';

// support_pre_payment_paginated_get
export const support_pre_payment_paginated_get = createAsyncThunk(
    'mod_profil/support_pre_payment_paginated_get',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_support_prepayment/groups/${values.groupId}/support_pre_payment/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Envoie d'un mail contenant un qrcode de remboursement
export const support_pre_payment_create = createAsyncThunk(
    'mod_profil/support_pre_payment_create',
    async (values) => {
        const response = await httpPost(
            `mod_support_prepayment/groups/${values.groupId}/support_pre_payment/`,
            {
                email: values.email,
                amount: values.amount,
                date_expiration: values.dateExpiration,
                incident_date: values.incidentDate,
                incident_reason: values.incidentReason,
                incident_equipment: values.incidentEquipment,
            },
        );
        return response.data;
    },
);

const supportPrepaymentSlices = createSlice({
    name: 'supportPrepayment',
    initialState: {
        loading: true,
        supportPrepayment: [],
        supportPrepaymentCount: 0,
    },
    extraReducers: (builder) => {
        builder
            /* support_pre_payment_paginated_get */
            .addCase(
                support_pre_payment_paginated_get.pending,
                (state, action) => {
                    state.supportPrepayment = [];
                },
            )
            .addCase(
                support_pre_payment_paginated_get.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.supportPrepayment = action.payload;
                    state.supportPrepaymentCount = action.payload.count;
                },
            )
            .addCase(
                support_pre_payment_paginated_get.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            // Envoie d'un mail contenant un qrcode de remboursement
            .addCase(support_pre_payment_create.fulfilled, (state, action) => {
                state.loading = false;
                state.supportPrepayment = action.payload;
                state.supportPrepaymentCount = action.payload.count;
            })
            .addCase(support_pre_payment_create.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export default supportPrepaymentSlices.reducer;
