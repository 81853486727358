import { useTranslation } from 'react-i18next';
import {
    Pagination,
    Table,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import TicketActionsMenu from '../ticketActionsMenu/TicketActionsMenu';
import { ePromotionStatus } from '../../assets/js/ennum';
import { dateFormat } from '../../../modUtils/functions/dateFormat';

const TicketsTable = ({
    supportsCount,
    supports,
    toggleModalTestPromo,
    toggleModalSendTicketMail,
    toggleModalCloseTicket,
    toggleModalDetailTicket,
    currentPage,
    limit,
    handlePaginationChange,
}) => {
    const { t } = useTranslation();
    const formatDate = 'DD/MM/YYYY HH:mm';

    const columns = [
        {
            title: t('modalViewTickets.table.transCreate'),
            dataIndex: 'date_creation',
            key: 'date_creation',
            ellipsis: true,
            render: (text, record) => (
                <div>
                    <span>{dateFormat(text, formatDate)}</span>
                </div>
            ),
        },
        {
            title: t('modalViewTickets.table.transCode'),
            dataIndex: 'support_number',
            key: 'support_number',
            ellipsis: true,
        },
        {
            title: t('modalViewTickets.table.transStartDate'),
            dataIndex: 'date_validity_start',
            key: 'date_validity_start',
            ellipsis: true,
            render: (text, record) => (
                <div>
                    <span>{dateFormat(text, formatDate)}</span>
                </div>
            ),
        },
        {
            title: t('modalViewTickets.table.transEndDate'),
            dataIndex: 'date_validity_end',
            key: 'date_validity_end',
            ellipsis: true,
            render: (text, record) => (
                <div>
                    <span>{text ? dateFormat(text, formatDate) : '---'}</span>
                </div>
            ),
        },
        {
            title: t('modalViewTickets.table.TransUse'),
            dataIndex: 'used',
            key: 'used',
            ellipsis: true,
            render: (text, record) => (
                <div>
                    <span>
                        {record.use_count || 0} / {record.count_usage_max || 0}
                    </span>
                </div>
            ),
        },
        {
            title: t('modalViewTickets.table.transWhen'),
            dataIndex: 'date_print',
            key: 'date_print',
            ellipsis: true,
            render: (text, record) => (
                <div>
                    <span>{dateFormat(text, formatDate) || '---'}</span>
                </div>
            ),
        },
        {
            title: t('modalViewTickets.table.transStatus'),
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            render: (text, record) => (
                <span className='capitalize'>{t(ePromotionStatus[text])}</span>
            ),
        },
        {
            title: t('modalViewTickets.table.transActions'),
            dataIndex: '',
            key: '',
            render: (text, record) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <TicketActionsMenu
                        record={record}
                        toggleModalTestPromo={toggleModalTestPromo}
                        toggleModalSendTicketMail={toggleModalSendTicketMail}
                        toggleModalCloseTicket={toggleModalCloseTicket}
                        toggleModalDetailTicket={toggleModalDetailTicket}
                        promoTitle={record.date_creation}
                    />
                </div>
            ),
        },
    ];

    // ouvrir modal detail sale quand on click sur la ligne
    const handleRowClick = (rowKey) => {
        toggleModalDetailTicket(rowKey);
    };

    return (
        <div>
            <Table
                columns={columns}
                onRowClick={(rowKey, event) => handleRowClick(rowKey, event)}
                dataSource={supports}
                tableClassName={'min-w-[970px] '}
                noDataMessage={t('transNoData')}
            />
            {supportsCount > 0 && (
                <div className='flex flex-row justify-end mt-4'>
                    <Pagination
                        current={currentPage}
                        defaultCurrent={currentPage}
                        defaultPageSize={limit}
                        showSizeChange={false}
                        total={supportsCount}
                        onChange={handlePaginationChange}
                        hideOnSinglePage={true}
                    />
                </div>
            )}
        </div>
    );
};

export default TicketsTable;
