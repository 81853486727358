import React, { useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import FormLegend from 'ck_commun/src/app/modUtils/components/FormLegend'; // TODO A DEPLACER
import SubmitButton from 'ck_commun/src/app/modUtils/components/buttons/submitButton/SubmitButton';
import LoadingStatusForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/LoadingStatusForm';
import ButtonDisabled from '../../buttons/ButtonDisabled';

/**
 * Formulaire générique
 * @param {Object} props
 * @param {Array} props.fields - Liste des champs
 * @param {Function} props.onSubmit - Fonction à exécuter à la soumission
 * @param {Object} props.initialValues - Valeurs initiales
 * @param {string} [props.layout="vertical"] - Layout du formulaire
 */
export default function CkForm({
    form,
    layout = 'vertical',
    status = null,
    loading,
    onFinish,
    className = '',
    initialValues,
    ...props
}) {
    const { t } = useTranslation();
    const [hasErrors, setHasErrors] = useState(false);

    return (
        <div className={className}>
            <Form
                form={form}
                layout={layout}
                onFinish={onFinish}
                initialValues={initialValues}
                onFieldsChange={() => {
                    // Lorsque le champ est modifié, vérifier si des erreurs sont présentes
                    const errors = form
                        ?.getFieldsError()
                        ?.some(({ errors }) => errors.length > 0);
                    setHasErrors(errors);
                }}
            >
                {props.children}

                <FormLegend requiredFormItemLegend={true} />

                <div className='flex justify-center mt-2'>
                    {loading ? (
                        <ButtonDisabled>{t('ckForm.loading')}</ButtonDisabled>
                    ) : hasErrors ? (
                        <ButtonDisabled>
                            {t('ckForm.errorsInTheForm')}
                        </ButtonDisabled>
                    ) : (
                        <SubmitButton htmlType='submit' />
                    )}
                </div>

                <LoadingStatusForm status={status} loading={loading} />
            </Form>
        </div>
    );
}
