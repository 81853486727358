import { useTranslation } from 'react-i18next';
import {
    DatePicker,
    Input,
    Modal,
    Select,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { Formik } from 'formik';
import RangePicker from '../../../modUtils/components/componentsLibrary/datePicker/RangePicker';
import { dateFormat } from '../../../modUtils/functions/dateFormat';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { optionsStatus } from '../modalCreatePromotion/constants';
import Button from '../../../modUtils/components/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_promotion_support_detail,
    update_promotion_support_detail,
} from '../../slices/promotionSlice';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AlertMessage from '../../../modUtils/components/AlertMessage';

const ModalDetailTicket = ({
    modalDetailTicket,
    toggleModalDetailTicket,
    groupId,
    promotionId,
    supportId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { supportDetail, loadingSupportDetail } = useSelector(
        (state) => state.promotion,
    );
    const format = 'DD/MM/YYYY';
    const [startDate, setStartDate] = useState(
        dateFormat(supportDetail?.date_validity_start, format),
    );
    const [endDate, setEndDate] = useState(
        dateFormat(supportDetail?.date_validity_start, format),
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(
            get_promotion_support_detail({
                groupId,
                promotionId,
                supportId,
            }),
        );
    }, [supportId]);

    const handleRangeChange = (start, end, setFieldValue) => {
        // Fonction pour gérer le changement de plage de dates
        if (start) {
            setStartDate(start);
            setFieldValue('date_validity_start', moment(start).unix());
        }
        if (end) {
            setEndDate(end);
            setFieldValue('date_validity_end', moment(end).unix());
        }
    };

    const handleSubmit = (values) => {
        setLoading(true);
        try {
            const response = dispatch(
                update_promotion_support_detail({
                    groupId,
                    promotionId,
                    supportId,
                    status: values.status,
                }),
            );
            if (response) {
                message.success({
                    content: (
                        <AlertMessage
                            status={response?.status}
                            alertMessage={t(
                                'success.transSuccessUpdateSupport',
                            )}
                        />
                    ),
                });
                toggleModalDetailTicket(supportId);
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('errors.transErrorUpdateSupport')}
                        errorDetail={error?.data.message}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            isOpen={modalDetailTicket}
            onClose={toggleModalDetailTicket}
            title={t('modalViewTickets.modalDetailTicket.transTitle')}
            style={{
                modalTitle: {
                    color: '#25292D',
                    fontSize: '20px',
                    lineHeight: '23px',
                },
            }}
        >
            {loadingSupportDetail ? (
                <Spinner />
            ) : (
                <Formik
                    initialValues={{
                        date_creation: supportDetail?.date_creation,
                        date_print: supportDetail?.date_print,
                        date_validity_start: supportDetail?.date_validity_start,
                        date_validity_end: supportDetail?.date_validity_end,
                        status: supportDetail?.status,
                        support_number: supportDetail?.support_number,
                        use_count: supportDetail?.use_count,
                    }}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ values, handleSubmit, setFieldValue }) => (
                        <div>
                            <Input
                                label={t('modalViewTickets.table.transCode')}
                                value={values.support_number}
                                disabled
                            />
                            <DatePicker
                                width=' w-full'
                                format={format}
                                disabled={true}
                                classNameLabel='mt-4'
                                label={t('modalViewTickets.table.transCreate')}
                                color='neutral'
                                selectedDate={dateFormat(
                                    values.date_creation,
                                    format,
                                )}
                            />
                            <RangePicker
                                className='mt-4 flex items-center gap-2'
                                startLabel={t(
                                    'modalViewTickets.table.transStartDate',
                                )}
                                endLabel={t(
                                    'modalViewTickets.table.transEndDate',
                                )}
                                color={'neutral'}
                                format={format}
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                onRangeChange={(start, end) =>
                                    handleRangeChange(start, end, setFieldValue)
                                }
                            />
                            <Input
                                className={'mt-4'}
                                label={t('modalViewTickets.table.TransUse')}
                                value={values.use_count}
                                disabled
                            />
                            <Select
                                containerWidth={'full'}
                                dropdownMatchSelectWidth
                                block
                                selectLabel={t(
                                    'modalViewTickets.table.transStatus',
                                )}
                                style={{
                                    selectLabel: { color: '#25292D' },
                                }}
                                options={optionsStatus(t)}
                                defaultValue={[values.status]}
                                onSelect={(e) => setFieldValue('status', e)}
                            />
                            <div className='mt-4 text-center'>
                                <Button
                                    color={'success'}
                                    onClick={() => handleSubmit(values)}
                                >
                                    {loading ? (
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    twoToneColor='#fff'
                                                    spin
                                                />
                                            }
                                        />
                                    ) : (
                                        t(
                                            'createPromotionModal.transValidateButton',
                                        )
                                    )}
                                </Button>
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export default ModalDetailTicket;
