import React from 'react';
// import FilterSupport from "ck_commun/src/app/modUtils/components/filters/FilterSupport";
// import SubmitButton from "ck_commun/src/app/modUtils/components/buttons/submitButton/SubmitButton";
// import FilterBySearchSite from "ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite";
// import FilterByDate from "ck_commun/src/app/modUtils/components/filters/FilterByDate";
// import { Select } from "ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);
//* debut NOMBRE DE NOUVEAUX BADGES (MENSUEL)
export const barOptions1 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        // title: {
        //   display: true,
        //   text: "NOMBRE DE NOUVEAUX BADGES (MENSUEL)",
        // },
    },
};

const barLabels1 = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
];
const barDataset1 = [10, 20, 30, 40, 50, 60, 70];
export const barData1 = {
    labels: barLabels1,
    datasets: [
        {
            label: 'Nouveaux badges',
            data: barDataset1,
            backgroundColor: '#005DFF',
        },
    ],
};
//* end NOMBRE DE NOUVEAUX BADGES (MENSUEL)

//* debut EVOLUTION DU MONTANT TOTAL DES CRÉDITS (MENSUEL)
const lineOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },

        // title: {
        //   display: true,
        //   text: "EVOLUTION DU MONTANT TOTAL DES CRÉDITS (MENSUEL)",
        // },
    },
    elements: {
        line: {
            tension: 0.5,
        },
    },
};

const lineLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
];
const lineDataset = [10, 200, 30, 450, 150, 60, 70];
export const lineData = {
    labels: lineLabels,
    datasets: [
        {
            label: 'Montant des crédits',
            data: lineDataset,
            borderColor: '#01A73E',
            backgroundColor: '#01A73E',
            pointStyle: 'circle',
        },
    ],
};

//* end EVOLUTION DU MONTANT TOTAL DES CRÉDITS (MENSUEL)

//* debut DERNIÈRES UTILISATIONS REGROUPÉES PAR MOIS
export const barOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        // title: {
        //   display: true,
        //   text: "DERNIÈRES UTILISATIONS REGROUPÉES PAR MOIS",
        // },
    },
};

const barLabels2 = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
];
const barDataset2 = [10, 20, 30, 40, 50, 60, 70];
export const barData2 = {
    labels: barLabels2,
    datasets: [
        {
            label: 'Montant des badges',
            data: barDataset2,
            backgroundColor: '#FF7F00',
        },
    ],
};
//* end DERNIÈRES UTILISATIONS REGROUPÉES PAR MOIS

//* MONTANT MOYEN PAR BADGE PAR TYPE D'ÉQUIPEMENT
export const barOptions3 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        // title: {
        //   display: true,
        //   text: "MONTANT MOYEN PAR BADGE PAR TYPE D'ÉQUIPEMENT",
        // },
    },
};

const barLabels3 = ['Portique', 'Piste HP', 'Aspirateur'];
const barDataset3 = [10, 20, 30];
const backgroundColors3 = ['#FF7F00', '#005DFF', '#01A73E'];

export const barData3 = {
    labels: [''],
    datasets: barLabels3.map((label, index) => ({
        label,
        data: [barDataset3[index]],
        backgroundColor: [backgroundColors3[index]],
    })),
};
//* fin MONTANT MOYEN PAR BADGE PAR TYPE D'ÉQUIPEMENT

//* debut UTILISATION DES BADGES PAR TYPE D'ÉQUIPEMENT
export const pieoptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'left',
        },
        // title: {
        //   display: true,
        //   text: "UTILISATION DES BADGES PAR TYPE D'ÉQUIPEMENT",
        // },
    },
};

export const piedata = {
    labels: ['Portique', 'Piste HP', 'Aspirateur'],
    datasets: [
        {
            data: [10, 20, 30],
            backgroundColor: ['#FF7F00', '#005DFF', '#01A73E'],
            borderColor: ['#FF7F00', '#005DFF', '#01A73E'],
            borderWidth: 1,
        },
    ],
};
//* fin UTILISATION DES BADGES PAR TYPE D'ÉQUIPEMENT

export default function BadgesStatistics() {
    return (
        <BuildingPage />
        // <div>
        //   <div className="flex-display flex-row align-items-end filters">
        //     {/* <FilterSupport /> */}
        //     <FilterBySearchSite /> <FilterByDate /> <SubmitButton />
        //   </div>

        //   <div>
        //     <div className="uppercase text-[17px] leading-[24px] text-black font-bold m-[20px] text-center">
        //       STATISTIQUE DU NOMBRE DES NOUVEAUX BADGES
        //     </div>
        //     <div className="flex-display flex-row justify-content-between">
        //       <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[49%]'>
        //         <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
        //           NOMBRE DE NOUVEAUX BADGES (MENSUEL)
        //         </div>
        //         <Bar options={barOptions1} data={barData1} />
        //       </div>
        //       <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[49%]'>
        //         <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
        //           EVOLUTION DU MONTANT TOTAL DES CRÉDITS (MENSUEL)
        //         </div>
        //         <Line options={lineOptions} data={lineData} />
        //       </div>
        //     </div>
        //   </div>

        //   <div>
        //     <div className="uppercase text-[17px] leading-[24px] text-black font-bold m-[20px] text-center">STATISTIQUE D'UTILISATION</div>
        //     <div className="flex-display flex-row justify-content-between ">
        //       <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[32%]'>
        //         <div className="flex-display flex-row justify-content-between ">
        //           <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
        //             DERNIÈRES UTILISATIONS REGROUPÉES PAR MOIS
        //           </div>
        //           <Select
        //             defaultActiveFirstOption={true}
        //             dropdownMatchSelectWidth={true}
        //             options={[
        //               { label: "Par montant", value: "amount" },
        //               { label: "Par quantité", value: "quantity" },
        //             ]}
        //             onChange={(value) => console.log("Selected:", value)}
        //             onDeselect={(value) => console.log("Deselected:", value)}
        //             onSelect={(value, option) =>
        //               console.log("Selected:", value, option)
        //             }
        //             width={140}
        //           />
        //         </div>
        //         <Bar options={barOptions2} data={barData2} />
        //       </div>
        //       <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[32%]'>
        //       <div className="flex-display flex-row justify-content-between ">
        //         <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
        //           MONTANT DES CRÉDITS DES BADGES
        //         </div>
        //         <Select
        //             defaultActiveFirstOption={true}
        //             dropdownMatchSelectWidth={true}
        //             options={[
        //               { label: "Pour 6 mois", value: 0.5 },
        //               { label: "Pour 1 an", value: 1 },
        //               { label: "Pour 2 ans", value: 2 },
        //             ]}
        //             onChange={(value) => console.log("Selected:", value)}
        //             onDeselect={(value) => console.log("Deselected:", value)}
        //             onSelect={(value, option) =>
        //               console.log("Selected:", value, option)
        //             }
        //             width={140}
        //           />
        //           </div>
        //           {/* <table>
        //             <thead>
        //               <tr>
        //                 <td>dernière utilisation</td>
        //                 <td>-1an</td>
        //                 <td>+1an</td>
        //               </tr>
        //               </thead>
        //               <tbody>
        //                 <tr><td>nombre de badges</td>
        //                 <td>700</td>
        //                 <td>400</td>
        //                 </tr>
        //                 <tr>
        //                   <td>montant</td>
        //                   <td>1120</td>
        //                   <td>680</td>
        //                 </tr>
        //               </tbody>
        //           </table> */}
        //       </div>
        //       <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[32%]'>
        //         <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
        //           RECHARGEMENT MOYEN PAR BADGES
        //         </div>
        //       </div>
        //     </div>
        //   </div>

        //   <div>
        //     <div className="uppercase text-[17px] leading-[24px] text-black font-bold m-[20px] text-center">STATISTIQUE PAR TYPE D'ÉQUIPEMENT</div>
        //     <div className="flex-display flex-row justify-content-between">
        //       <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[32%]'>
        //         <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
        //           MONTANT MOYEN PAR BADGE PAR TYPE D'ÉQUIPEMENT
        //         </div>
        //         <Bar options={barOptions3} data={barData3} />
        //       </div>
        //      <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[32%]'>
        //         <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
        //           UTILISATION DES BADGE PAR TYPE D'ÉQUIPEMENT
        //         </div>
        //         <Pie options={pieoptions} data={piedata} />
        //       </div>
        //       <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[32%]'>
        //         <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
        //           MONTANT MOYEN MENSUEL DÉBITÉ PAR BADGE PAR TYPE D'ÉQUIPEMENT
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
    );
}
