import { useState } from 'react';
import DropdownActionsMenu from '../../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu';
import { useTranslation } from 'react-i18next';

const TicketActionsMenu = ({
    record,
    toggleModalTestPromo,
    toggleModalSendTicketMail,
    toggleModalCloseTicket,
    toggleModalDetailTicket,
}) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const hide = () => {
        setVisible(false);
    };

    const toggleModal = () => {
        hide();
        toggleModalTestPromo();
    };

    const handleModalSendTicketMail = (record) => {
        hide();
        toggleModalSendTicketMail(record);
    };

    const handleModalCloseTicket = (record) => {
        hide();
        toggleModalCloseTicket(record);
    };

    const menuItems = [
        // <p className='flex items-center gap-2 text-xs' onClick={toggleModal}>
        //     {t('modalViewTickets.table.transTestCode')}
        // </p>,
        // <p
        //     className='flex items-center gap-2 text-xs'
        //     onClick={handleModalSendTicketMail}
        // >
        //     {t('modalViewTickets.transSendByMail')}
        // </p>,
        <p
            className={`flex items-center gap-2 text-xs${record.status === 3 ? ' cursor-not-allowed text-[#c9c8c8]' : ''}`}
            onClick={() =>
                record.status === 3 ? null : handleModalCloseTicket(record)
            }
        >
            {t('modalViewTickets.transCloseCode')}
        </p>,
        <p
            className='flex items-center gap-2 text-xs'
            onClick={() => toggleModalDetailTicket(record)}
        >
            {t('modalViewTickets.table.transDetails')}
        </p>,
    ];

    const handleVisibleChange = () => {
        setVisible((prevVisible) => !prevVisible);
    };

    return (
        <DropdownActionsMenu
            menuItems={menuItems}
            handleVisibleChange={handleVisibleChange}
            visible={visible}
        />
    );
};

export default TicketActionsMenu;
