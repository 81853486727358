// on set la variable IS_PROD a true quand le site est en ligne , on la passe a false uniquement si on est sur l'url de dev
// je prefère la mettre a true par défaut pour éviter les risques

const IS_PROD =
    process.env.NODE_ENV === 'production' &&
    window.location.origin !== 'https://dev.v3.ck-wash.fr';
const SRC_IMG = process.env.PUBLIC_URL + '/img/menuIcons/';
// const APP_LOGO = process.env.PUBLIC_URL + "/ckwashLogo.png";
const APP_LOGO = process.env.PUBLIC_URL + '/ckwashLogo.svg';
const APP_NAME = 'CkWash Manager';
const APP_SHORT_NAME = 'CkWash';
const CONTACT_PHONE_NUMBER = '0 473 289 246';
const HOMEPAGE_ROUTE = '/home';

const URL_MOBILE_APPLE =
    'https://apps.apple.com/fr/app/ckwash-manager/id6477200533';
const URL_MOBILE_ANDROID =
    'https://play.google.com/store/apps/details?id=fr.cksquare.ckwash_manager';

export {
    APP_NAME,
    APP_LOGO,
    APP_SHORT_NAME,
    CONTACT_PHONE_NUMBER,
    HOMEPAGE_ROUTE,
    IS_PROD,
    SRC_IMG,
    URL_MOBILE_APPLE,
    URL_MOBILE_ANDROID,
};
