import React from 'react';
import { Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import MarkerRequired from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/MarkerRequired';

export default function InputEmailForm({
    label = 'inputEmailForm.email',
    className = '',
    formRequired = true,
}) {
    const { t } = useTranslation();

    return (
        <div className={className}>
            {formRequired && <MarkerRequired />}

            <Label label={label} />
            <Form.Item
                name='email'
                rules={[
                    {
                        required: formRequired,
                        message: t('inputEmailForm.emailIsRequired'),
                    },
                    {
                        type: 'email',
                        message: t('inputEmailForm.emailIsInvalid'),
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </div>
    );
}
