import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from '../componentsLibrary/componentsLibrary';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/handlePermissions';
import PermissionFadeContainer from '../permissionFadeContainer/PermissionFadeContainer';
import { useTranslation } from 'react-i18next';

export default function FilterByEquipmentStatus({
    onValueChange,
    equipment__is_connected,
    equipment__state,
    defaultLimit,
}) {
    const { selectedGroup } = useSelector((state) => state.manager);
    const { countEquipmentStatus } = useSelector((state) => state.equipment);
    let sitesCount = selectedGroup?.site_count;
    let showStatusCount = sitesCount <= defaultLimit; //si le nombre des sites du groupe est supérieur au nombre des sites récupérés 'default limit), les counts avant les labels ne seront pas affichés (impossible de les calculer)
    const [selectedOptions, setSelectedOptions] = useState([
        { groupValue: 1, options: [] },
        { groupValue: 2, options: [] },
    ]);
    const { t } = useTranslation();
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_equipment:show_device',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_equipment:show_device',
        groupPermissions,
    );

    let numberOfSelectedOptions =
        selectedOptions[0].options.length + selectedOptions[1].options.length;

    const optionErreur = {
        groupValue: 1,
        options: [
            {
                label: (
                    <PermissionFadeContainer
                        className='inline'
                        permission={verifyGroupPermission}
                    >
                        <span>
                            <img
                                className='w-4 mr-2'
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/equipmentStatusIcons/operatingStatus.svg'
                                }
                                alt='img'
                            />
                            {showStatusCount &&
                                countEquipmentStatus.workingCount}{' '}
                            {t('filterEquipmentStatus.inUse')}
                        </span>
                    </PermissionFadeContainer>
                ),
                value: 'working',
            },
            {
                label: (
                    <PermissionFadeContainer
                        className='inline'
                        permission={verifyGroupPermission}
                    >
                        <span>
                            <img
                                className='w-4 mr-2'
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/equipmentStatusIcons/errorConnected.svg'
                                }
                                alt='img'
                            />
                            {showStatusCount && countEquipmentStatus.errorCount}{' '}
                            {t('filterEquipmentStatus.error')}
                        </span>
                    </PermissionFadeContainer>
                ),
                value: 'error',
            },
            {
                label: (
                    <PermissionFadeContainer
                        className='inline'
                        permission={verifyGroupPermission}
                    >
                        <span>
                            <img
                                className='w-4 mr-2'
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/equipmentStatusIcons/offlineConnected.svg'
                                }
                                alt='img'
                            />
                            {showStatusCount && countEquipmentStatus.outCount}{' '}
                            {t('filterEquipmentStatus.offline')}
                        </span>
                    </PermissionFadeContainer>
                ),
                value: 'out',
            },
        ],
    };

    const verticalBar = {
        label: (
            <div
                className='w-full my-2.5'
                style={{
                    borderTop: '1.5px solid #338EF5',
                }}
            />
        ),
    };

    const optionConnection = {
        groupValue: 2,
        options: [
            {
                label: (
                    <span>
                        <img
                            className='w-[18px] mr-[9px]'
                            src={
                                process.env.PUBLIC_URL +
                                '/img/equipmentStatusIcons/connectedStatus.png'
                            }
                            alt='img'
                        />
                        {showStatusCount && countEquipmentStatus.connectedCount}{' '}
                        {t('filterEquipmentStatus.connected')}
                    </span>
                ),
                value: 'true',
            },
            {
                label: (
                    <span>
                        <img
                            className='w-[18px] mr-[9px]'
                            src={
                                process.env.PUBLIC_URL +
                                '/img/equipmentStatusIcons/offlineStatus.png'
                            }
                            alt='img'
                        />
                        {showStatusCount &&
                            countEquipmentStatus.disconnectedCount}{' '}
                        {t('filterEquipmentStatus.disconnected')}
                    </span>
                ),
                value: 'false',
            },
        ],
    };

    let optionsGroup = [];

    if (verifyPermission) {
        optionsGroup = [
            optionErreur,
            {
                ...verticalBar,
                ...optionConnection,
            },
        ];
    } else {
        optionsGroup = [
            {
                ...optionConnection,
            },
        ];
    }

    const handleChange = (
        value,
        selectedOptionsList,
        setSelectedOptionsList,
    ) => {
        if (selectedOptionsList?.includes(value)) {
            const newValue = selectedOptionsList.filter((val) => val !== value);
            setSelectedOptionsList(newValue);
            handleDeselect(value);
        } else {
            // Appeler le callback
            if (value == 'working' || value == 'out' || value == 'error') {
                setSelectedOptionsList([value, ...selectedOptions[1].options]);
                setSelectedOptions((prevSelectedOptions) => {
                    var updatedOptions = [...prevSelectedOptions];
                    const groupIndex = updatedOptions.findIndex(
                        (group) => group.groupValue === 1,
                    );

                    //updatedOptions[groupIndex].options.push(value)
                    updatedOptions[groupIndex].options = [value];

                    return updatedOptions;
                });
            } else {
                setSelectedOptionsList([...selectedOptions[0].options, value]);
                setSelectedOptions((prevSelectedOptions) => {
                    const updatedOptions = [...prevSelectedOptions];
                    const groupIndex = updatedOptions.findIndex(
                        (group) => group.groupValue === 2,
                    );
                    updatedOptions[groupIndex].options = [value];
                    return updatedOptions;
                });
            }
        }
        onValueChange(selectedOptions);
    };

    const handleDeselect = (deselectedValue, selectedList) => {
        setSelectedOptions((prevSelectedOptions) => {
            const updatedOptions = [...prevSelectedOptions];

            // Find the group that contains the deselected value
            const groupIndex = updatedOptions.findIndex((group) =>
                group.options.includes(deselectedValue),
            );

            if (groupIndex !== -1) {
                // Remove the deselected value from the options array
                updatedOptions[groupIndex].options = updatedOptions[
                    groupIndex
                ].options.filter((option) => option !== deselectedValue);
            }
            return updatedOptions;
        });

        //onValueChange(selectedOptions)
    };

    return (
        <>
            <Select
                defaultValue={[...equipment__is_connected, ...equipment__state]}
                multiple
                placeholder={
                    t('sharedEquipmentFormFilter.filters') +
                    (numberOfSelectedOptions > 0
                        ? `(${numberOfSelectedOptions})`
                        : '')
                }
                options={optionsGroup}
                dropdownMatchSelectWidth={false}
                onDeselect={handleDeselect}
                onSelect={handleChange}
                onlyOption={true}
                style= {{
                    select: { width: "200px"},
                    selectOptions: { width: "200px"}
                }}
            />
        </>
    );
}
