import React from 'react';
import Button from './Button';

export default function ButtonDisabled({
    onClick,
    size,
    className = {},
    ...props
}) {
    return (
        <Button
            size={size}
            className={`!cursor-not-allowed bg-[#f5f5f5] border-[#d9d9d9] text-[#00000040] ${className}`}
            onClick={onClick}
        >
            {props.children}
        </Button>
    );
}
