import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import MarkerRequired from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/MarkerRequired';

export default function TextAreaForm({
    name,
    label,
    className = '',
    formRequired = false,
    messageRequired,
}) {
    const { t } = useTranslation();

    return (
        <div className={className}>
            {formRequired && <MarkerRequired />}

            <Label label={label} />

            <Form.Item
                name={name}
                rules={[
                    {
                        required: formRequired,
                        message:
                            messageRequired ||
                            t('textAreaForm.fieldIsRequired'),
                    },
                ]}
            >
                <Input.TextArea />
            </Form.Item>
        </div>
    );
}
