import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get_activity_cumulative } from '../../slices/cumulativeSlice';
import SubmitButton from '../../../modUtils/components/buttons/submitButton/SubmitButton';
import FilterByEquipmentType from '../../../modUtils/components/filters/FilterByEquipmentType';
import FilterBySearchSite from 'ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite';
import FilterByDate from '../../../modUtils/components/filters/FilterByDate';
import FilterByTotalType from '../../components/FilterByTotalType';
import {
    eEquipmentType,
    eCdbsPaymentType,
} from '../../../../commun_global/enums';
import moment from 'moment';
import { Spinner } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/numberFormat';
import { ReactComponent as EqualSvg } from '../../assets/images/equal.svg';
import { ReactComponent as DecreaseSvg } from '../../assets/images/decrease.svg';
import { ReactComponent as IncreaseSvg } from '../../assets/images/increase.svg';
import IncreaseIcon from '../../assets/images/increase-bordered.svg';
import DecreaseIcon from '../../assets/images/decrease-bordered.svg';
import EqualIcon from '../../assets/images/equal.svg';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { message } from 'antd';
import './ActivityCAPage.scss';
import i18next from 'i18next';

const paymentTotalTypes = ['group_real', 'group_other'];

export default function ActivityCAPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId, selectedGroup } = useSelector((state) => state.manager);
    const { activityCumuleByGroupId } = useSelector(
        (state) => state.cumulative,
    );
    const [loading, setLoading] = useState(true);
    const siteCount = selectedGroup?.site_count;
    const [siteList, setSiteList] = useState();
    const [equipmentTypeList, setEquipmentTypeList] = useState();
    const [paramList, setParamList] = useState([
        'by_sites',
        'by_equipment_types',
        'by_equipments',
    ]);
    const [paymentTypeList, setPaymentTypeList] = useState(paymentTotalTypes);
    const formatDate = 'DD/MM/YYYY HH:mm';
    const [startDate, setStartDate] = useState(
        moment().startOf('month').format(formatDate),
    );
    const [endDate, setEndDate] = useState(moment().format(formatDate));
    const [showTotalsReal, setShowTotalsReal] = useState(); //test en front dépend du filtre sélectionné pour afficher ou pas les colonnes total réel et total réel N-1
    //fonction qui appelle get_activity_cumulative
    const [showTotalWithPrePaid, setShowTotalWithPrePaid] = useState();
    const getActivityCumulative = async () => {
        let showTotalsReal = paymentTypeList.includes(paymentTotalTypes[0]); //vérifier si le filtre paymentGroup contiennent "group_real"
        let showTotalWithPrePaid = paymentTypeList.includes(
            paymentTotalTypes[1],
        );
        setShowTotalsReal(showTotalsReal);
        setShowTotalWithPrePaid(showTotalWithPrePaid);
        if (paymentTypeList?.length < 1 || paramList?.length < 1) {
            message.warning({
                content: (
                    <AlertMessage alertMessage={t('activity.alertMessage')} />
                ),
            });
        } else {
            setLoading(true);
            try {
                await dispatch(
                    get_activity_cumulative({
                        groupId,
                        param: paramList?.join(','),
                        equipmentTypes: equipmentTypeList?.join(','),
                        sites: siteList?.join(','),
                        paymentGroup: paymentTypeList?.join(','),
                        startDate: startDate
                            ? moment(startDate, formatDate).unix()
                            : null,
                        endDate: endDate
                            ? moment(endDate, formatDate).unix()
                            : null,
                    }),
                ).unwrap();
            } catch (error) {
                message.error({
                    content: (
                        <AlertMessage
                            status={error?.status}
                            alertMessage={t('activity.errorMessage')}
                            errorDetail={error?.data?.detail}
                        />
                    ),
                });
            } finally {
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        if (groupId && startDate && endDate) {
            getActivityCumulative();
        }
    }, [groupId, i18next.language]);

    function handleSiteValueChange(selectedList) {
        setSiteList(selectedList?.length > 0 ? selectedList : null);
    }

    const handleEquipmentType = (selectedList) => {
        setEquipmentTypeList(selectedList?.length > 0 ? selectedList : null);
    };

    const handleTotalType = (selectedList) => {
        setParamList(selectedList[0]?.options);
        setPaymentTypeList(selectedList[1]?.options);
    };

    const handleDate = (selectedDate) => {
        setStartDate(selectedDate?.length > 0 ? selectedDate[0] : null);
        setEndDate(selectedDate?.length > 0 ? selectedDate[1] : null);
    };

    const TableComponent = ({ data }) => {
        //style ligne de tableau
        const renderRowClassName = (activityType) => {
            switch (activityType) {
                case 0:
                    return 'bg-[#40adff] text-white h-10 ';
                case 1:
                    return 'bg-[#40adff] text-white h-9 text-xs font-semibold';
                case 2:
                    return 'bg-primary-light-color h-7 text-[11px] font-bold';
                case 3:
                    return 'bg-white-color h-7 text-[10px]';
                default:
                    return 'bg-[#40adff] text-white h-10 '; //ajouté parce que case 0 n'est pas traité
            }
        };
        //style première colonne du tableau
        const renderFirstColClassName = (activityType) => {
            switch (activityType) {
                case 2:
                    return 'pl-5 truncate';
                case 3:
                    return "pl-7 before:content-['-_']";
                default:
                    return 'pl-2 truncate';
            }
        };
        //retourne l'icône à afficher à gauche des totaux
        const renderCompareIcon = (total, totalLastYear) => {
            let icon;

            if (total > totalLastYear)
                icon = <IncreaseSvg className='w-[22px] text-accent-color' />;
            else if (total < totalLastYear)
                icon = (
                    <DecreaseSvg className='w-[22px] text-destructive-color' />
                );
            else if (total == totalLastYear)
                icon = (
                    <EqualSvg className='w-[16px] text-muted-foreground-color' />
                );

            return <div>{icon}</div>;
        };
        //retourne l'entête du tableau
        const renderTableHeader = () => (
            <tr className='bg-[#ecf6fc] h-10 text-white text-xs uppercase font-bold'>
                <td className='bg-background-color'></td>
                {data?.lines[0]?.payments_real?.map((payment) => (
                    <td
                        key={payment.type}
                        className='text-[#868686] border border-solid border-border-color truncate ...'
                    >
                        {t(eCdbsPaymentType[payment.type])}
                    </td>
                ))}
                {showTotalsReal && (
                    <td className='bg-[#1b8adc] border border-solid border-border-color truncate'>
                        {t('activity.tabColReal')}
                    </td>
                )}
                {showTotalsReal && (
                    <td className='bg-[#78bdee] border border-solid border-border-color truncate'>
                        {t('activity.tabColRealN')}
                    </td>
                )}
                {data?.lines[0]?.payments_other?.map((payment) => (
                    <td
                        key={payment.type}
                        className='text-[#868686] border border-solid border-border-color truncate'
                    >
                        {t(eCdbsPaymentType[payment.type])}
                    </td>
                ))}
                {/* <td className="bg-secondary-color border border-solid border-white">
          total prépayé
        </td>
        <td className="bg-neutral-color-200 border border-solid border-white">
          total prépayé n-1
        </td> */}
                {showTotalWithPrePaid && (
                    <>
                        <td
                            className='bg-[#40adff] border border-solid border-border-color truncate'
                            width={'12%'}
                        >
                            total
                        </td>
                        <td className='bg-[#78bdee] border border-solid border-border-color truncate'>
                            total n-1
                        </td>
                    </>
                )}
            </tr>
        );
        //retourne une ligne de données du tableau
        const renderTableRow = (line, activityType) => (
            <tr
                className={renderRowClassName(activityType)}
                key={line?.object_id || line?.name}
            >
                <td
                    className={`text-left text-black border border-solid border-border-color min-w-36 truncate ${renderFirstColClassName(
                        activityType,
                    )}  ${activityType !== 2 && activityType !== 3 ? 'font-bold text-primary-foreground-color' : ''}`}
                >
                    {line?.name}
                </td>
                {line?.payments_real?.map((payment) => (
                    <td
                        key={payment.type}
                        className={`border border-solid border-border-color text-black ${
                            activityType !== 2 &&
                            activityType !== 3 &&
                            'text-primary-foreground-color'
                        }`}
                    >
                        {amountFormatWithCurrencySymbol(payment.amount)}
                    </td>
                ))}
                {showTotalsReal && (
                    <td
                        className={`${
                            activityType !== 2 && activityType !== 3
                                ? 'bg-[#40adff]'
                                : 'bg-[#1b8adc]'
                        } text-white text-right border border-solid border-border-color`}
                    >
                        <div className='justify-between flex truncate'>
                            {renderCompareIcon(
                                line?.total_real,
                                line?.total_real_last_year,
                            )}
                            {amountFormatWithCurrencySymbol(line?.total_real)}
                        </div>
                    </td>
                )}
                {showTotalsReal && (
                    <td
                        className={`${
                            activityType !== 2 && activityType !== 3
                                ? 'bg-[#9acef2]'
                                : 'bg-[#78bdee]'
                        } text-white text-right border border-solid border-border-color`}
                    >
                        {amountFormatWithCurrencySymbol(
                            line?.total_real_last_year,
                        )}
                    </td>
                )}
                {line?.payments_other?.map((payment) => (
                    <td
                        key={payment.type}
                        className={`border border-solid border-border-color ${
                            activityType !== 2 && activityType !== 3
                                ? 'text-white'
                                : 'text-black'
                        }`}
                    >
                        {amountFormatWithCurrencySymbol(payment.amount)}
                    </td>
                ))}
                {/*
        //! les lignes suivantes commentées correspondent aux colonnes total prépayé et total prépayé n-1 
      
         <td
          className={`${
            activityType !== 2 && activityType !== 3
              ? "bg-secondary-color"
              : "bg-primary-color"
          } text-white text-right border border-solid border-border-color`}
        >
          <div className="justify-between flex">
            {renderCompareIcon(line?.total_other, line?.total_other_last_year)}
            {amountFormatWithCurrencySymbol(line?.total_other)}
          </div>
        </td>
        <td
          className={`${
            activityType !== 2 && activityType !== 3
              ? "bg-muted-foreground-color"
              : "bg-muted-foreground-color"
          } text-white text-right border border-solid border-border-color`}
        >
          {amountFormatWithCurrencySymbol(line?.total_other_last_year)}
        </td> */}
                {showTotalWithPrePaid && (
                    <td className='bg-[#40adff] text-right text-white border border-solid border-border-color'>
                        <div className='justify-between flex text-nowrap gap-1'>
                            {renderCompareIcon(
                                line?.total_real + line?.total_other,
                                line?.total_real_last_year +
                                    line?.total_other_last_year,
                            )}
                            {amountFormatWithCurrencySymbol(
                                line?.total_real + line?.total_other,
                            )}
                        </div>
                    </td>
                )}
                {showTotalWithPrePaid && (
                    <td className='bg-[#78bdee] text-right text-white border border-solid border-border-color'>
                        {amountFormatWithCurrencySymbol(
                            line?.total_real_last_year +
                                line?.total_other_last_year,
                        )}
                    </td>
                )}
            </tr>
        );
        //fonction récursive retourne toutes les lignes du tableau
        const renderTableRowsRecursive = (
            lines,
            activityType,
            isFirstLine = true,
        ) => {
            return lines?.flatMap((line, index) => [
                isFirstLine && activityType === 1 ? renderTableHeader() : null,
                index === 1 && isFirstLine
                    ? null
                    : renderTableRow(line, activityType),
                isFirstLine && index === lines.length - 1 ? <br /> : null,
                line?.child && line?.child.lines
                    ? renderTableRowsRecursive(
                          line?.child.lines,
                          line?.child?.activity_type,
                          false,
                      )
                    : null,
            ]);
        };

        return (
            <table className='text-center w-full table-fixed table-cumulative-activity min-w-[1150px]'>
                <thead>
                    {data.activity_type === 0 ? renderTableHeader() : null}
                </thead>
                <tbody>
                    {renderTableRowsRecursive(
                        data.lines,
                        data.lines?.child?.activity_type,
                    )}
                </tbody>
            </table>
        );
    };

    return (
        <>
            <div className='filters gap-2 flex flex-row items-end flex-wrap md:max-w-[calc(100vw-500px)] mb-10'>
                <FilterByEquipmentType onValueChange={handleEquipmentType} />
                {siteCount > 1 && (
                    <FilterBySearchSite onValueChange={handleSiteValueChange} />
                )}
                <FilterByDate onValueChange={handleDate} />
                <FilterByTotalType
                    onValueChange={handleTotalType}
                    paramList={paramList}
                    paymentTypeList={paymentTypeList}
                />
                <SubmitButton onClick={() => getActivityCumulative()} />
            </div>
            {loading ? (
                <>
                    <Spinner />
                </>
            ) : (
                <>
                    <TableComponent data={activityCumuleByGroupId} />
                </>
            )}
        </>
    );
}
