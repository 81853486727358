import React from 'react';
import Input from './Input';
import { PiMagnifyingGlass } from 'react-icons/pi';

export default function InputSearch({ id, placeholder }) {
    return (
        <div
            className={`
            flex flex-row items-center justify-between relative cursor-pointer
            border border-solid border-input-color rounded-md bg-card-color
            mt-6 mr-2.5 mb-4 px-4 py-2 w-48 text-base`}
        >
            <Input
                id={id}
                placeholder={placeholder}
                style={{ all: 'unset', width: '130px' }}
            />

            <PiMagnifyingGlass className='w-[40px]' />
        </div>
    );
}
