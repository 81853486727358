import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get_actions_by_support_by_group_paginated } from '../slices/walletLocalSlice';
import SupportActionMenuButton from './SupportActionMenuButton';
import { dateFormat } from '../../modUtils/functions/dateFormat';
import {
    Spinner,
    Table,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { amountFormatWithCurrencySymbol } from '../../modUtils/functions/numberFormat';
import { message } from 'antd';
import AlertMessage from '../../modUtils/components/AlertMessage';
import useScrollHandler from '../../modUtils/hooks/useScrollHandler';
import i18next from 'i18next';

export default function SupportActionsList({ supportId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { supportActionsById, supportActionsCount, loading } = useSelector(
        (state) => state.walletLocal,
    );
    const { groupId } = useSelector((state) => state.manager);
    const [actionAwaitStatus, setActionAwaitStatus] = useState(0);
    const [actions, setActions] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const defaultOffset = 0;
    const defaultLimit = 10;
    const incrementalLimit = 2;
    const [limit, setLimit] = useState(defaultLimit); // Nombre d'éléments à charger à chaque fois
    const [offset, setOffset] = useState(defaultOffset);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(false); //pour savoir s'il y'a encore de données à charger
    const getSupportActions = async (offset, limit) => {
        const response = await dispatch(
            get_actions_by_support_by_group_paginated({
                groupId,
                supportId,
                ordering: '-date',
                limit,
                offset,
            }),
        ).unwrap();

        const count = numberOfActionStatusIsAwaiting(response.results);
        setActionAwaitStatus(count);
        if (response.results.length === 0) {
            setHasMore(false);
        } else {
            setOffset(offset + limit);
            let hasMore = response.next !== null ? true : false; //tester si y'a encore de données à récupérer
            setHasMoreDataToLoad(hasMore);
        }
    };

    useEffect(() => {
        setActions([]);
        setOffset(0);
        setHasMoreDataToLoad(true);
        if (groupId && supportId) {
            getSupportActions(0, 10);
        }
    }, [groupId, supportId, supportActionsCount, i18next.language]);
    const onScrollEnd = () => {
        if (!loading && hasMore) {
            getSupportActions(offset, incrementalLimit);
        }
    };

    const { tableRef, handleScroll } = useScrollHandler(onScrollEnd);

    /**
     *la fonction actionStatus prend en paramètre @param status de l'action du badge est retourne l'icone correspondante au statut
     ** 0 = En cours , 1 = Annuler, 2 = Terminé
     */
    function actionStatus(status) {
        switch (status) {
            case 0:
                return (
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            '/img/badgesIcons/actionEnAttente.png'
                        }
                        alt='en attente'
                    ></img>
                );
            case 1:
                return (
                    <img
                        src={process.env.PUBLIC_URL + '/img/badgesIcons/x.svg'}
                        alt='annulé'
                    ></img>
                );
            case 2:
                return (
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            '/img/badgesIcons/actionExecuted.png'
                        }
                        alt='exécuté'
                    ></img>
                );
            default:
                return '';
        }
    }

    /**
     *la fonction numberOfActionStatusIsAwaiting prend en paramètre @param supportActionsById qui est la liste des actions du badge, calcule le nombre des actions ayant le status=0 et màj actionAwaitStatus
     */
    function numberOfActionStatusIsAwaiting(supportActionsById) {
        var count = 0;
        supportActionsById?.map((action) => {
            if (action.status == 0) count += 1;
        });
        return count;
    }
    const columns = [
        {
            title: t('table.tabColDate'),
            dataIndex: 'request_date',
            key: 'request_date',
            width: '9%',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColOrigin'),
            dataIndex: 'origin_label',
            key: 'origin_label',
            width: '8%',
        },
        {
            title: t('table.tabColAction'),
            dataIndex: 'type_label',
            key: 'type_label',
            align: 'left',
            bold: true,
            width: '10%',
        },
        {
            title: t('table.tabColAmount'),
            dataIndex: 'amount',
            key: 'amount',
            bold: true,
            width: '8%',
            render: (text) => (
                <span>{amountFormatWithCurrencySymbol(text)}</span>
            ),
        },
        {
            title: t('table.tabColBonus'),
            dataIndex: 'prime',
            key: 'prime',
            bold: true,
            width: '8%',
            render: (text) => (
                <span>{amountFormatWithCurrencySymbol(text)}</span>
            ),
        },
        {
            title: t('table.tabColState'),
            dataIndex: 'status',
            key: 'status',
            width: '5%',
            render: (text, record) => actionStatus(text),
        },
        {
            title: t('table.tabColExecuted'),
            dataIndex: 'executed_date',
            key: 'executed_date',
            bold: true,
            align: 'left',
            width: '13%',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColEquipment'),
            dataIndex: 'executed_equipment',
            key: 'executed_equipment',
            width: '15%',
            ellipsis: true,
            align: 'left',
        },
        {
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text, record) => (
                <SupportActionMenuButton
                    actionId={text}
                    actionStatus={record.status}
                    supportId={supportId}
                />
            ),
        },
    ];

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <div>
                    <div className='text-left font-semibold uppercase text-[#354052] text-[14px] leading-[18px]'>
                        {/* {actionAwaitStatus} action(s) en attente */}
                        {supportActionsCount} action(s)
                    </div>
                    <div ref={tableRef}>
                        <Table
                            columns={columns}
                            dataSource={supportActionsById}
                            pagination={false}
                            scroll={{ y: 350 }}
                            onScroll={handleScroll}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
