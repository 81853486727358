import React from 'react';

// Composant Button generique
export default function Button({
    onClick,
    color,
    size = 'md',
    className = {},
    ...props
}) {
    const sizeVariant = {
        sm: 'px-[15px] py-1 rounded-[10px] text-sm ',
        md: 'px-4 py-2 rounded-md text-base',
        lg: 'px-6 py-3 rounded-lg text-lg',
        xl: 'px-8 py-4 rounded-lg text-xl',
        xxl: 'px-10 py-5 rounded-lg text-2xl',
    };

    const colorVariants = {
        primary:
            'bg-primary-color hover:brightness-110 border-primary-color text-primary-foreground-color',
        outline:
            'bg-white hover:bg-primary-color border-primary-color text-primary-color hover:text-white',
        secondary:
            'bg-secondary-color text-secondary-foreground-color hover:brightness-110 border-secondary-color',
        thirty: 'bg-thirty-color text-thirty-foreground-color hover:brightness-110 border-thirty-color text-white',
        success:
            'bg-accent-color hover:brightness-110 border-accent-color text-white cursor-move',
        error: 'bg-destructive-color hover:brightness-110 border-destructive-color text-white',
        neutral:
            'bg-muted-foreground-color text-muted-foreground-color hover:brightness-110 border-muted-foreground-color text-white',
        dark: 'bg-primary-color filter brightness-50 !hover:brightness-60 border-primary-color text-white !cursor-move',
    };

    return (
        <button
            className={`border border-solid cursor-pointer
                ${colorVariants[color]}
                ${sizeVariant[size]}
                ${className}`}
            onClick={onClick}
            type='button'
        >
            {props.children}
        </button>
    );
}
