import React, { useState, useEffect } from 'react';
import {
    Select,
    Table,
    Input,
    Spinner,
    Button,
    Pagination,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat } from '../../modUtils/functions/dateFormat';
// import PrintButton from "../../modUtils/components/buttons/printButton/PrintButton";
import SupportMenuButton from '../components/SupportMenuButton';
import SubmitButton from '../../modUtils/components/buttons/submitButton/SubmitButton';
import FilterByDate from '../../modUtils/components/filters/FilterByDate';
import SupportFilter from '../components/SupportFilter';
import {
    get_supports_by_group_paginated,
    create_blacklist_history_by_support,
} from '../slices/walletLocalSlice';
import moment from 'moment';
import SupportTransactionsList from '../components/supportTransactionsList/SupportTransactionsList';
import SupportActionsList from '../components/SupportActionsList';
import { amountFormatWithCurrencySymbol } from '../../modUtils/functions/numberFormat';
import { message } from 'antd';
import AlertMessage from '../../modUtils/components/AlertMessage';
import i18next from 'i18next';
import NoData from '../../modUtils/components/noData/NoData';
import { ReactComponent as ZoomSvg } from '../../modUtils/assets/images/zoom.svg';

export default function SupportsPage() {
    const { t } = useTranslation();
    const orderByOptions = [
        {
            value: 'last_visit',
            label: t('SupportPage.optionLastVisit'),
        },
        {
            value: 'amount',
            label: t('SupportPage.optionAmount'),
        },
        {
            value: 'status',
            label: t('SupportPage.optionStatus'),
        },
    ];
    const dispatch = useDispatch();
    const { groupId } = useSelector((state) => state.manager);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { badges, supportsCount } = useSelector((state) => state.walletLocal);
    const [loading, setLoading] = useState(true);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [supportId, setSupportId] = useState(badges[0]?.id);
    const [selectedSupportsKeys, setSelectedSupportsKeys] = useState([]);
    const formatDate = 'DD/MM/YYYY HH:mm';
    // début déclaration filtres
    const [startDate, setStartDate] = useState(
        moment().startOf('month').format(formatDate),
    );
    const [endDate, setEndDate] = useState(
        moment().endOf('month').format(formatDate),
    );
    const [statusList, setStatusList] = useState([]);
    const [blacklistList, setBlacklistList] = useState([]);
    const [entityLink, setEntityLink] = useState([]);
    const [actionInProgress, setActionInProgress] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [ordering, setOrdering] = useState('-last_visit');
    //fin déclaration filtres

    // rowSelection object indique le besoin de row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedSupportsKeys(selectedRowKeys);
        },
    };
    // appelée quand on clique sur une ligne du tableau des badges
    const handleRowClick = (rowKey) => {
        setSupportId(rowKey);
    };
    //fonction qui appelle l'api get_supports_by_group_paginated pour récupérer la liste des badges
    const getSupports = async (values) => {
        const blacklistFilter = handleBlacklistListValues();

        setLoading(true);
        let response = {};
        try {
            response = await dispatch(
                get_supports_by_group_paginated({
                    groupId,
                    dateStart: startDate
                        ? moment(startDate, formatDate).unix()
                        : null,
                    dateEnd: endDate
                        ? moment(endDate, formatDate).unix()
                        : null,
                    limit: values.limit,
                    offset: values.offset,
                    search: searchValue,
                    actionInProgress: actionInProgress[0]
                        ? actionInProgress[0]
                        : '',
                    entityLink: entityLink?.join(','),
                    status: statusList?.join(','),
                    ordering: ordering,
                    blacklisted: blacklistFilter[0],
                }),
            ).unwrap();
            if (response?.results) {
                setSupportId(response?.results[0]?.id); //récupérer l'id du premier badge retourné par l'api
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la récupération de la liste des badges`}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setEntityLink([]);
        setStatusList([]);
        setBlacklistList([]);
        setActionInProgress([]);
        //appel à l'api support_get_paginated_get pour récupérer la liste des badges
        if (startDate && endDate && groupId) {
            getSupports({ limit: limit, offset: offset });
        }
    }, [groupId, i18next.language]);

    const handleSearchInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    // Fonction de gestion de changement de pagination
    const handlePaginationChange = (page, pageSize) => {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * pageSize;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        setLimit(pageSize);
        // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
        getSupports({ limit: pageSize, offset: newOffset });
    };

    //la fonction badgeStatusIcon vérifie si le badge est blacklisté ou non ( 0: en stock, 1: utilisé, 2: blacklisté )
    function badgeStatusIcon(status, blacklisted) {
        if (blacklisted)
            return (
                <img
                    width='15px'
                    src={
                        process.env.PUBLIC_URL +
                        '/img/badgesIcons/blacklist.svg'
                    }
                />
            );
        else if (!blacklisted && status == 0)
            return (
                <img
                    width='15px'
                    src={process.env.PUBLIC_URL + '/img/badgesIcons/stock.svg'}
                />
            );
        else return '';
    }
    //fonction appelée pour blacklister ou déblacklister un ou plusiers badges
    const blacklistSupport = async (selectedSupportsKeys) => {
        // tester si selectedSupportsKeys est vide il faut afficher "veuillez cocher les lignes depuis la liste"
        if (selectedSupportsKeys.length == 0) {
            // Afficher un message indiquant de sélectionner des badges à blacklister
            message.warning({
                content: (
                    <AlertMessage
                        alertMessage={
                            "Aucun badge n'est sélectionné. Veuillez cocher une ou plusieurs lignes depuis la liste"
                        }
                    />
                ),
            });
        }

        //appel à l'api create_blacklist_history_by_support pour blacklister/déblacklister un badge */
        for (const supportId of selectedSupportsKeys) {
            const badge = badges?.find((badge) => badge.id === supportId);
            if (badge) {
                const blocked = !badge.is_blacklisted;
                setConfirmLoading(true);
                try {
                    await dispatch(
                        create_blacklist_history_by_support({
                            groupId,
                            supportId,
                            blocked,
                        }),
                    ).unwrap();
                } catch (error) {
                    message.error({
                        content: (
                            <AlertMessage
                                status={error?.status}
                                alertMessage={
                                    'Une erreur est survenue lors de la modification de la liste noire des badges'
                                }
                                errorDetail={error?.data?.detail}
                            />
                        ),
                    });
                } finally {
                    setConfirmLoading(false);
                }
            }
        }
    };

    const handleDate = (selectedDate) => {
        setStartDate(selectedDate?.length > 0 ? selectedDate[0] : null);
        setEndDate(selectedDate?.length > 0 ? selectedDate[1] : null);
    };
    //mettre à jour les valeurs des filtres du select
    const handleFilterSupport = (selectedList) => {
        setEntityLink(selectedList[0]?.options);
        setStatusList(selectedList[1]?.options);
        setBlacklistList(selectedList[2].options);
        setActionInProgress(selectedList[3]?.options);
    };
    //traitement des valeurs du filtre lié au blacklistage
    const handleBlacklistListValues = () => {
        var contains2 = blacklistList?.includes(2); //blacklisté
        var contains3 = blacklistList.includes(3); //non blacklisté
        if (contains2 && !contains3) {
            return ['true'];
        } else if (!contains2 && contains3) {
            return ['false'];
        } else if ((contains2 && contains3) || blacklistList.length == 0) {
            return [];
        }
    };
    const onExecuteSubmitBtn = () => {
        getSupports({ limit: limit, offset: offset });
    };
    const columns = [
        {
            title: t('table.tabColNBadge'),
            dataIndex: 'badge_number',
            key: 'badge_number',
            ellipsis: true,
            width: '20%',
            // sorter: (a, b) => a.num_badge.localeCompare(b.num_badge),
            render: (text) => <span>{text}</span>,
        },
        {
            title: t('table.tabColNameEntreprise'),
            dataIndex: 'entity',
            key: 'entity',
            width: '30%',
            ellipsis: true,
            align: 'left',
            bold: true,
            render: (text, record) =>
                record?.entity?.entity_customer
                    ? `${record?.entity?.entity_customer?.name} ${record?.entity?.entity_customer?.first_name} `
                    : record?.entity?.entity_company?.name,
            // sorter: (a, b) => a.customer.localeCompare(b.customer),
        },
        {
            title: t('table.tabColLastVisit'),
            dataIndex: 'last_visit_date',
            key: 'last_visit_date',
            width: '25%',
            render: (text) => (
                <span className='truncate'>{dateFormat(text)}</span>
            ),
        },
        {
            title: t('table.tabColPay'),
            dataIndex: 'amount',
            key: 'amount',
            width: '15%',
            // sorter: (a, b) => a.solde.localeCompare(b.solde),
            className: 'table-column-accent',
            headerClassName: 'table-column-accent',
            render: (text) => (
                <span className='truncate'>
                    {text
                        ? amountFormatWithCurrencySymbol(text)
                        : amountFormatWithCurrencySymbol(0)}
                </span>
            ),
        },

        {
            title: 'FRAUDES',
            dataIndex: 'suspected_fraud_count',
            key: 'suspected_fraud_count',
            width: '10%',
            render: (text, record) =>
                record.suspected_fraud_count > 0 && (
                    <>
                        <img
                            width={20}
                            src={
                                process.env.PUBLIC_URL +
                                '/img/badgesIcons/fraudeIcone.svg'
                            }
                            alt={record.suspected_fraud_count + 'fraudes'}
                        />{' '}
                        <span>{record.suspected_fraud_count}</span>
                    </>
                ),
        },

        {
            title: t('table.tabColState'),
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            // sorter: (a, b) => a.status.localeCompare(b.status),
            render: (text, record) =>
                badgeStatusIcon(record.status, record.is_blacklisted),
        },
        {
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text, record) => <SupportMenuButton support={record} />,
        },
    ];
    return (
        <>
            {confirmLoading && <Spinner />}
            <div className='filters gap-2 flex flex-row items-end flex-wrap md:max-w-[calc(100vw-500px)]'>
                <SupportFilter
                    onValueChange={handleFilterSupport}
                    statusList={statusList}
                    entityLink={entityLink}
                    actionInProgress={actionInProgress}
                    blacklistList={blacklistList}
                />

                <div className='search-site flex flex-row'>
                    <input
                        id='searchInput'
                        type='text'
                        className='search-site'
                        value={searchValue}
                        onChange={handleSearchInputChange}
                        placeholder='N°Badge, Client'
                    />
                    <ZoomSvg className='text-input-color' />
                </div>
                <FilterByDate onValueChange={handleDate}></FilterByDate>
                <div>
                    <Select
                        defaultActiveFirstOption={true}
                        selectLabel={t('SupportPage.selectLabel')}
                        options={orderByOptions}
                        onSelect={(value, option) => setOrdering(value)}
                        style= {{
                            select: { width: "160px"},
                            selectOptions: { width: "160px"}
                        }}
                    />
                </div>
                <SubmitButton onClick={() => onExecuteSubmitBtn()} />
            </div>

            {loading ? (
                <Spinner />
            ) : badges?.length == 0 ? (
                <NoData text={t('transNodata')} />
            ) : (
                <div className='h-fit'>
                    {badges.length > 0 && (
                        <Pagination
                            defaultCurrent={currentPage}
                            defaultPageSize={limit}
                            showQuickJumper={true}
                            showTotal={false}
                            total={supportsCount}
                            hideOnSinglePage={true}
                            onChange={handlePaginationChange}
                            showSizeChange={false}
                            onShowSizeChange={handlePaginationChange}
                        />
                    )}
                    <div className='grid grid-cols-2 gap-4 h-full'>
                        <div className='col-span-2 2xl:col-span-1'>
                            <div className='flex flex-row justify-between mb-2 items-end'>
                                <div className='text-left font-semibold uppercase text-[#354052] text-[14px] leading-[18px]'>
                                    {supportsCount} badge(s)
                                </div>
                                <div className='flex-display justify-content-center'>
                                    <Button type='image'>
                                        <img
                                            width='30px'
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/badgesIcons/blacklist.svg'
                                            }
                                            onClick={() =>
                                                blacklistSupport(
                                                    selectedSupportsKeys,
                                                )
                                            }
                                        />
                                        <span>
                                            {t('badge.badgeBlacklistButton')}
                                        </span>
                                    </Button>
                                </div>
                            </div>

                            <Table
                                //rowKey={(record) => record.id}
                                columns={columns}
                                dataSource={badges}
                                rowSelection={{
                                    ...rowSelection,
                                }}
                                onRowClick={handleRowClick}
                                rowClassName={(record, index) =>
                                    record.id == supportId
                                        ? 'table-row-clicked'
                                        : ''
                                } // changer la couleur de la ligne sélectionnée */
                            />
                        </div>
                        <div className='col-span-2 2xl:col-span-1'>
                            <div className='flex flex-row justify-between mb-2 items-end'>
                                <div className='text-left font-semibold uppercase text-[#354052] text-[14px] leading-[18px]'>
                                    BADGE N°
                                    {
                                        //récupérer numéro de badge à partir de son id
                                        badges?.map((badge) => {
                                            if (badge.id === supportId)
                                                return badge.badge_number;
                                        })
                                    }
                                </div>
                                <div
                                    className='flex-display justify-content-center'
                                    style={{ height: 49 }}
                                >
                                    {/* <PrintButton /> */}
                                </div>
                            </div>
                            <div className='content-between'>
                                <div className='max-h-[39vh] min-h-[39vh] mb-2.5'>
                                    <SupportTransactionsList
                                        supportId={supportId}
                                    />
                                </div>
                                <div className='max-h-[39vh] min-h-[39vh]'>
                                    <SupportActionsList supportId={supportId} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
