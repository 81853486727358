import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    AlertMessage,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import ConfirmationModal from '../../../modUtils/components/confirmationModal/ConfirmationModal';
import { imachines_gb_machine_kinds_by_equipment_by_site_by_group_get } from '../../slices/machineSlice';
import { dateFormat } from '../../../modUtils/functions/dateFormat';
import { machine_management_by_id_action } from '../../slices/machineSlice';
import { eMachinesOperatingStatus } from '../../../../commun_global/js/machines/enums';
import Button from '../../../modUtils/components/buttons/Button';
import LoadingSpinner from '../../../modUtils/components/componentsLibrary/spinner/LoadingSpinner';
import ButtonRefresh from '../../../modUtils/components/buttons/ButtonRefresh';

export default function MachineAction({ siteId, equipment, machineOnClick }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const { machines } = useSelector((state) => state.machine);
    const { messageAlert } = useSelector((state) => state.machine);
    const [confirmModalVisibleStart, setConfirmModalVisibleStart] =
        useState(false);
    const [confirmModalVisibleService, setConfirmModalVisibleService] =
        useState(false);
    const [confirmModalVisibleRestart, setConfirmModalVisibleRestart] =
        useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [machine, setmachine] = useState(machineOnClick);

    const timeout = (setConfirmModalVisible) => {
        setTimeout(() => {
            setConfirmModalVisible(false);
            setConfirmLoading(false);
            fetchData();
        }, 1000);
    };

    const handleConfirmStartOk = () => {
        setConfirmLoading(true);
        dispatch(
            machine_management_by_id_action({
                groupId: groupId,
                siteId: siteId,
                equipmentId: equipment.id,
                machineId: machine.id,
                command:
                    machine.operating_status == 5 ||
                    machine.operating_status == 10
                        ? 'start'
                        : 'stop',
            }),
        );
        timeout(setConfirmModalVisibleStart);
    };

    const handleConfirmServiceOk = () => {
        setConfirmLoading(true);
        dispatch(
            machine_management_by_id_action({
                groupId: groupId,
                siteId: siteId,
                equipmentId: equipment.id,
                machineId: machine.id,
                command: 'value_set',
                value: {
                    out_of_order:
                        machine?.operating_status >= 0 &&
                        machine?.operating_status <= 2
                            ? false
                            : true,
                },
            }),
        );
        timeout(setConfirmModalVisibleService);
    };

    const handleConfirmRestartOk = () => {
        setConfirmLoading(true);
        dispatch(
            machine_management_by_id_action({
                groupId: groupId,
                siteId: siteId,
                equipmentId: equipment.id,
                machineId: machine.id,
                command: 'power_set',
                power: 2,
            }),
        );
        timeout(setConfirmModalVisibleRestart);
    };

    const handleConfirmCancel = (setConfirmModalVisible) => {
        setConfirmModalVisible(false);
    };
    const handleConfirm = (setConfirmModalVisible) => {
        setConfirmModalVisible(true);
    };

    async function fetchData() {
        setLoading(true);
        await dispatch(
            imachines_gb_machine_kinds_by_equipment_by_site_by_group_get({
                groupId: groupId,
                equipmentId: equipment.id,
                siteId: siteId,
            }),
        );
        setLoading(false);
    }

    useEffect(() => {
        if (machines[0] !== undefined && machines[0].length > 0) {
            setmachine(setMachineById());
        }
    });

    function setMachineById() {
        let machineById;
        machines[0].map((machines) => {
            machines.machines.map((machine) => {
                if (machineOnClick?.id === machine['id']) {
                    machineById = machine;
                }
            });
        });
        return machineById;
    }

    const setStyleTextColorSatus = () => {
        let style = '';

        if (machine?.operating_status >= 1 && machine?.operating_status <= 4) {
            style = 'text-destructive-color';
        } else if (
            machine?.operating_status === 5 ||
            machine?.operating_status === 9
        ) {
            style = 'text-accent-color';
        } else {
            style = 'text-primary-color';
        }

        return style;
    };

    const getSatusButonStart = () => {
        let status;

        if (machine?.operating_status >= 0 && machine?.operating_status <= 2) {
            status = 'neutral';
        } else if (
            machine?.operating_status === 5 ||
            machine?.operating_status === 10
        ) {
            status = 'success';
        } else {
            status = 'error';
        }

        return status;
    };

    return loading ? (
        <Spinner />
    ) : (
        <div className='font-bold p-5'>
            <ButtonRefresh onClick={() => fetchData()} />

            <div className='text-primary-color text-center flex justify-center'>
                {/* titre label */}
                <p className='text-4xl'>
                    N°{machine?.id} - {machine?.label}
                </p>
            </div>

            <table>
                {/* info machine */}
                <tbody>
                    <tr>
                        <td className='text-end w-24'>
                            {t('machineAction.status')}
                        </td>
                        <td className='pl-2.5'>
                            <span className={`${setStyleTextColorSatus()}`}>
                                {t(
                                    eMachinesOperatingStatus[
                                        machine?.operating_status
                                    ],
                                )}
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td className='text-end w-24'>
                            {t('machineAction.startingDate')}
                        </td>
                        <td className='pl-2.5'>
                            {dateFormat(machine?.starting_date, 'HH:mm')}
                        </td>
                    </tr>
                </tbody>
            </table>

            {/* button Démarrer et Service*/}
            <div className='flex justify-around mt-5'>
                {/* button start */}
                <Button
                    onClick={() =>
                        machine?.operating_status >= 0 &&
                        machine?.operating_status <= 2
                            ? null
                            : handleConfirm(setConfirmModalVisibleStart)
                    }
                    color={getSatusButonStart()}
                >
                    {machine?.operating_status >= 0 &&
                    machine?.operating_status <= 2
                        ? t('machineAction.outOfService')
                        : t('machineAction.startButton')}
                </Button>

                {/* button service */}
                <Button
                    color='thirty'
                    onClick={() => handleConfirm(setConfirmModalVisibleService)}
                >
                    {machine?.operating_status >= 0 &&
                    machine?.operating_status <= 2
                        ? t('machineAction.operatingStatusInService')
                        : t('machineAction.operatingStatusOutOfService')}
                </Button>

                {/* confirm button start */}
                <ConfirmationModal
                    onOk={handleConfirmStartOk}
                    onCancel={() =>
                        handleConfirmCancel(setConfirmModalVisibleStart)
                    }
                    visible={confirmModalVisibleStart}
                    confirmLoading={confirmLoading}
                    content={
                        <div>
                            {machine?.operating_status === 5 ||
                            machine?.operating_status === 10
                                ? t('machineAction.operatingStatusStart')
                                : t('machineAction.operatingStatusStop')}
                            {confirmLoading && <LoadingSpinner />}
                        </div>
                    }
                />

                {/* confirm button service */}
                <ConfirmationModal
                    onOk={handleConfirmServiceOk}
                    onCancel={() =>
                        handleConfirmCancel(setConfirmModalVisibleService)
                    }
                    visible={confirmModalVisibleService}
                    confirmLoading={confirmLoading}
                    content={
                        <>
                            {machine?.operating_status >= 0 &&
                            machine?.operating_status <= 2
                                ? t(
                                      'machineAction.operatingStatusInServiceMessage',
                                  )
                                : t(
                                      'machineAction.operatingStatusOutOfServiceMessage',
                                  )}
                            {confirmLoading && <LoadingSpinner />}
                        </>
                    }
                />
            </div>

            {/* button Redémarrer */}
            {machine?.power_switch_use && (
                <div className='flex justify-around items-center mt-5 p-2.5 border-solid border-2 rounded-xl border-destructive-color'>
                    <div>
                        <p>{t('machineAction.messageProblem')}</p>
                        <p className='m-0'>
                            {t('machineAction.restartButtonRequest')}
                        </p>
                    </div>

                    <Button
                        color='primary'
                        onClick={() =>
                            handleConfirm(setConfirmModalVisibleRestart)
                        }
                    >
                        {t('machineAction.restartButton')}
                    </Button>

                    <ConfirmationModal
                        onOk={handleConfirmRestartOk}
                        onCancel={() =>
                            handleConfirmCancel(setConfirmModalVisibleRestart)
                        }
                        visible={confirmModalVisibleRestart}
                        confirmLoading={confirmLoading}
                        content={
                            <>
                                {t('machineAction.restartRequest')}
                                {confirmLoading && <LoadingSpinner />}
                            </>
                        }
                    />
                </div>
            )}

            {messageAlert?.status !== undefined && (
                <AlertMessage
                    statusMessage={messageAlert?.status ? 'success' : 'error'}
                    defaultMessage={
                        messageAlert?.status
                            ? t('machineAction.successAlert')
                            : t('machineAction.errorAlert')
                    }
                />
            )}
        </div>
    );
}
