import {
    Input,
    Modal,
    Select,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../modUtils/components/buttons/submitButton/SubmitButton';
import TicketsTable from '../ticketsTable/TicketsTable';
import FilterByDate from '../../../modUtils/components/filters/FilterByDate';
import moment from 'moment';
import { BsQrCode } from 'react-icons/bs';
import { ReactComponent as Block } from '../../../modUtils/assets/images/block.svg';
import ExportButton from '../../../modUtils/components/buttons/exportButton/ExportButton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_promotion_supports,
    get_supports,
} from '../../slices/promotionSlice';
import { FaMinusCircle } from 'react-icons/fa';
import { message } from 'antd';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { optionsStatus } from '../modalCreatePromotion/constants';

const ModalTickets = ({
    promotionId,
    modal,
    toggleModalTickets,
    toggleModalGenerateQrCode,
    toggleModalCloseTicketsGroup,
    toggleModalExportTicket,
    toggleModalTestPromo,
    toggleModalSendTicketMail,
    toggleModalCloseTicket,
    toggleModalDetailTicket,
    setSelectForm,
    selectForm,
    setStartDate,
    setEndDate,
    groupId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { promotions, supports, supportsCount, loadingSupport } = useSelector(
        (state) => state.promotion,
    );
    const formatDate = 'DD/MM/YYYY HH:mm';
    const [limit] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(1);

    function formatedDate(selectedDate) {
        let formatedDate;
        if (typeof selectedDate === 'object') {
            const date = selectedDate;
            formatedDate = moment(date, formatDate, true).format(formatDate);
        } else if (
            typeof selectedDate === 'string' &&
            selectedDate.length > 0
        ) {
            formatedDate = moment(selectedDate, formatDate, true).format(
                formatDate,
            );
        }
        return formatedDate;
    }

    //filtre par date
    const handleDateChange = (selectedDate) => {
        const formatedStartDate = formatedDate(selectedDate[0]);
        const formatedEndDate = formatedDate(selectedDate[1]);

        setStartDate(formatedStartDate);
        setEndDate(formatedEndDate);

        setSelectForm({
            ...selectForm,
            dateStart: formatedStartDate,
            dateEnd: formatedEndDate,
        });
    };

    const submitFilter = () => {
        getSupports();
    };

    // Fonction de gestion de changement de pagination
    const handlePaginationChange = (page) => {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
    };

    const getSupports = async () => {
        try {
            await dispatch(
                selectForm.promotion
                    ? get_promotion_supports({
                          groupId,
                          ...selectForm,
                          date_validity_start: moment(
                              selectForm.date_validity_start,
                              formatDate,
                          ).unix(),
                          date_validity_end: moment(
                              selectForm.date_validity_end,
                              formatDate,
                          ).unix(),
                          limit,
                          offset,
                      })
                    : get_supports({
                          groupId,
                          ...selectForm,
                          date_validity_start: moment(
                              selectForm.date_validity_start,
                              formatDate,
                          ).unix(),
                          date_validity_end: moment(
                              selectForm.date_validity_end,
                              formatDate,
                          ).unix(),
                          limit,
                          offset,
                      }),
            );
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('errors.transErrorGetListingSupport')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        }
    };

    useEffect(() => {
        if (selectForm.promotion)
            dispatch(
                get_promotion_supports({
                    groupId,
                    promotion: selectForm.promotion,
                    limit,
                    offset,
                }),
            );
        else
            dispatch(
                get_supports({
                    groupId,
                    limit,
                    offset,
                }),
            );
    }, [offset]);

    return (
        <Modal
            isOpen={modal}
            onClose={toggleModalTickets}
            title={t('modalViewTickets.transTitle')}
            width={'max-content'}
            height={700}
            style={{
                modalTitle: {
                    color: '#25292D',
                    fontSize: '20px',
                    lineHeight: '23px',
                },
            }}
        >
            <div className='flex flex-col sm:flex-row items-end gap-2 mb-4'>
                <Select
                    selectLabel={t('modalViewTickets.transFilterPromotion')}
                    options={[
                        {
                            value: null,
                            label: t('common.transAll'),
                        },
                        ...(promotions
                            ? promotions.map((promotion) => ({
                                  value: promotion.id,
                                  label: promotion.name,
                              }))
                            : []),
                    ]}
                    style={{
                        select: {
                            width: window.innerWidth > 639 ? 'auto' : '100%',
                            minWidth: '220px',
                        },
                        selectOptions: {
                            width: window.innerWidth > 639 ? 'auto' : '100%',
                            minWidth: '220px',
                        },
                    }}
                    defaultValue={
                        promotionId
                            ? [promotionId]
                            : selectForm.promotion
                              ? [selectForm.promotion]
                              : null
                    }
                    controlledValue={
                        promotionId
                            ? [promotionId]
                            : selectForm.promotion
                              ? [selectForm.promotion]
                              : null
                    }
                    defaultActiveFirstOption={true}
                    dropdownMatchSelectWidth={true}
                    onSelect={(e) =>
                        setSelectForm({
                            ...selectForm,
                            promotion: e,
                        })
                    }
                />
                <Select
                    selectLabel={t('modalViewTickets.table.transStatus')}
                    options={optionsStatus(t)}
                    style={{
                        select: {
                            width: window.innerWidth > 639 ? 'auto' : '100%',
                            minWidth: '180px',
                        },
                        selectOptions: {
                            width: window.innerWidth > 639 ? 'auto' : '100%',
                            minWidth: '180px',
                        },
                    }}
                    onSelect={(e) =>
                        setSelectForm({
                            ...selectForm,
                            status: e,
                        })
                    }
                    defaultActiveFirstOption={true}
                />
                <FilterByDate
                    onValueChange={handleDateChange}
                    defaultPeriod='lastSevenDays'
                />
                <SubmitButton
                    className='w-full sm:w-auto h-10 ml-16 gap-4'
                    onClick={submitFilter}
                />
            </div>
            <div className='flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between gap-4 sm:gap-32 mt-10 mb-2'>
                <Input
                    labelClassName={'text-[#338EF5]'}
                    className={'w-full sm:w-auto'}
                    label={t('modalViewTickets.transSearchQRCodePlaceholder')}
                    placeholder={'123456789'}
                    classnames={'h-10 w-full sm:w-[400px]'}
                    value={selectForm.qrcode_number}
                    onChange={(e) =>
                        setSelectForm({
                            ...selectForm,
                            qrcode_number: e.target.value,
                        })
                    }
                    onPressEnter={getSupports}
                />
                <div className='flex gap-4 items-center'>
                    <div
                        className={`flex flex-col items-center font-semibold text-[10px]${selectForm.promotion ? ' cursor-pointer' : ' cursor-not-allowed text-[#c9c8c8]'}`}
                        onClick={
                            selectForm.promotion
                                ? toggleModalGenerateQrCode
                                : null
                        }
                    >
                        <BsQrCode fontSize={30} />
                        {t('modalViewTickets.transGenerateCodes')}
                    </div>
                    <div
                        className={`flex flex-col items-center font-semibold text-[10px]${selectForm.promotion ? ' cursor-pointer' : ' cursor-not-allowed text-[#c9c8c8]'}`}
                        onClick={
                            selectForm.promotion
                                ? toggleModalCloseTicketsGroup
                                : null
                        }
                    >
                        {selectForm.promotion ? (
                            <Block width={30} height={30} />
                        ) : (
                            <FaMinusCircle color='#c9c8c8' fontSize={30} />
                        )}
                        {t('modalViewTickets.transCloseCode')}
                    </div>
                    <ExportButton
                        disabled={!selectForm.promotion}
                        onClick={
                            selectForm.promotion
                                ? toggleModalExportTicket
                                : null
                        }
                    />
                </div>
            </div>
            {loadingSupport ? (
                <Spinner />
            ) : (
                <TicketsTable
                    supportsCount={supportsCount}
                    supports={supports}
                    toggleModalTestPromo={toggleModalTestPromo}
                    toggleModalSendTicketMail={toggleModalSendTicketMail}
                    toggleModalCloseTicket={toggleModalCloseTicket}
                    toggleModalDetailTicket={toggleModalDetailTicket}
                    limit={limit}
                    currentPage={currentPage}
                    handlePaginationChange={handlePaginationChange}
                />
            )}
        </Modal>
    );
};

export default ModalTickets;
