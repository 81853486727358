import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AlertMessage,
    Button,
    Modal,
    Pagination,
    Select,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_cash_boxes_by_group_paginated,
    update_cash_box_detail_by_group,
} from '../../slices/statementSlice';
import AddNewCashBox from './AddNewCashBox/AddNewCashBox';
import { ReactComponent as Caisse } from 'ck_commun/src/app/modStatement/assets/images/manageCaisse.svg';
import { message, Tooltip } from 'antd';
import UpdateCashBox from './updateCashBox/UpdateCashBoxComponent';
import ConfirmationModal from '../../../modUtils/components/confirmationModal/ConfirmationModal';
import { get_sites_by_group_paginated } from '../../../modManager/slices/managerSlice';
import { FaTrash } from 'react-icons/fa';

const ManageCashBoxButton = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId, sites } = useSelector((state) => state.manager);
    const [loading, setLoading] = useState(false);
    const [cashboxes, setCashboxes] = useState([]);
    const [limit, setLimit] = useState(6);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [cashBoxesCount, setCashBoxesCout] = useState(0);
    const [cashBoxToDelete, setCashBoxToDelete] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [site, setSite] = useState(null);
    const options = [
        { label: t('CashBoxes.all'), value: 'all' },
        { label: t('CashBoxes.old'), value: 'old' },
        { label: t('CashBoxes.current'), value: 'current' },
    ];
    const [isOldFilter, setIsOldFilter] = useState(options[2].value);
    const [cashboxesFiltred, setCashboxesFiltred] = useState([]);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false); // État pour le modal de confirmation
    const [confirmLoading, setConfirmLoading] = useState(false);
    const openConfirmModal = (cashBoxId, cashboxName) => {
        setCashBoxToDelete({ id: cashBoxId, name: cashboxName });
        setConfirmModalVisible(true);
    };
    useEffect(() => {
        if (groupId) {
            dispatch(
                get_sites_by_group_paginated({
                    groupId,
                    limit: 100,
                    offset: 0,
                }),
            );
        }
    }, [dispatch, groupId]);

    const getCashBox = async () => {
        let allCashboxes = [];
        let offset = 0;
        const limit = 50; // Peut être ajusté selon les besoins de l'API
        let hasMoreData = true;

        setLoading(true);
        try {
            while (hasMoreData) {
                const params = {
                    groupId: groupId,
                    limit: limit,
                    offset: offset,
                    site: site,
                };
                const response = await dispatch(
                    get_cash_boxes_by_group_paginated(params),
                ).unwrap();
                // Ajouter les résultats de la page actuelle aux résultats cumulés
                allCashboxes = [...allCashboxes, ...response.results];
                // Si on a moins de résultats que la limite, on sait qu'il n'y a plus de pages à charger
                if (response.results.length < limit) {
                    hasMoreData = false;
                } else {
                    // Sinon, on augmente l'offset pour passer à la page suivante
                    offset = offset + limit;
                }
            }
            //verifier si des cashboxes on ete recuperees pour le site selectionne
            if (allCashboxes.length === 0) {
                setCashboxes([]);
                setCashboxesFiltred([]);
                setCashBoxesCout(allCashboxes.length);
            } else {
                setCashboxes(allCashboxes);
                setCashBoxesCout(allCashboxes.length);
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('CashBoxes.anErrorOccurred')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSiteValueChange = async (value) => {
        setSite(value);
    };
    useEffect(() => {
        if (groupId) {
            getCashBox();
        }
    }, [site, groupId]);
    //sans useEffect on avait un probleme on devait cliquer plusieurs fois sur le site slectionné pour qui lise l id du site,
    const applyFilter = (cashboxes, filter) => {
        let filtred = cashboxes;
        if (filter === 'old') {
            filtred = cashboxes.filter((item) => item.is_old === true);
        } else if (filter === 'current') {
            filtred = cashboxes.filter((item) => item.is_old === false);
        }
        setCashboxesFiltred(filtred);
        setCurrentPage(1);
    };
    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setLimit(pageSize);
    };

    const handleFilterChange = (value) => {
        setIsOldFilter(value);
        setOffset(0);
        setCurrentPage(1);

        // Si les cashboxes ne sont pas encore chargées, tu peux attendre qu'elles soient récupérées
        if (cashboxes.length === 0) {
            getCashBox().then(() => {
                applyFilter(cashboxes, value);
            });
        } else {
            applyFilter(cashboxes, value);
        }
    };

    const handleDelete = async (cashBoxId, cashboxName) => {
        try {
            await dispatch(
                update_cash_box_detail_by_group({
                    groupId,
                    isOld: true,
                    cashBoxId: cashBoxId,
                    name: cashboxName,
                }),
            );
            message.success(t('CashBoxes.successDelete'));
            getCashBox();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('CashBoxes.anErrorOccurredDelete')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        }
    };
    useEffect(() => {
        // Une fois que les cashboxes sont chargées, applique le filtre par défaut (current)
        if (cashboxes.length > 0) {
            applyFilter(cashboxes, isOldFilter);
        }
    }, [cashboxes, isOldFilter]);
    const paginatedCashboxes = cashboxesFiltred.slice(
        (currentPage - 1) * limit,
        currentPage * limit,
    );
    return (
        <>
            <Button type='image'>
                <Caisse
                    onClick={() => {
                        setModalVisible(true);
                    }}
                />
                <span className='APP_tablePre-btnLabel normal-case'>
                    {t('CashBoxes.manageCashboxButton')}
                </span>
            </Button>
            <Modal
                isOpen={modalVisible}
                onClose={() => setModalVisible(false)}
                width={850}
                height={850}
            >
                <span class='relative'>
                    <span class='z-10 bg-white flex justify-center font-bold text-primary-color text-lg '>
                        {t('CashBoxes.cashboxes')} ({cashBoxesCount})
                    </span>

                    <div className='flex justify-between items-center mb-4'>
                        <Select
                            selectLabel={
                                <span className='text-black normal-case'>
                                    {t('addNewCashbox.cashboxSite')}
                                </span>
                            }
                            value={site}
                            onSelect={(value) => {
                                handleSiteValueChange(value);
                            }}
                            options={[
                                {
                                    value: null,
                                    label: t('CashBoxes.selectAllSite'),
                                },
                                ...(Array.isArray(sites)
                                    ? sites.map((site) => ({
                                          value: site.id,
                                          label: site.name,
                                      }))
                                    : []),
                            ]}
                            placeholder={
                                site
                                    ? sites.find(
                                          (selection) => selection.id === site,
                                      )?.name
                                    : t('addNewCashbox.cashboxSite')
                            }
                            style={{
                                select: { width: '160px' },
                                selectOptions: { width: '160px' },
                            }}
                        />

                        <div className='mt-8'>
                            {site === null ? (
                                <>
                                    <Tooltip
                                        title={t('CashBoxes.tootlipTitle')}
                                    >
                                        <span>
                                            {' '}
                                            <AddNewCashBox
                                                disabled={disabled}
                                            />
                                        </span>
                                    </Tooltip>
                                </>
                            ) : (
                                <AddNewCashBox
                                    refresh={getCashBox}
                                    site={site}
                                />
                            )}
                        </div>
                    </div>
                </span>
                <span class='flex items-center mt-3'>
                    <span class='h-px flex-1 bg-[#a4d0f1]'></span>
                </span>
                <div>
                    <div className='flex flex-row justify-between whitespace-nowrap'>
                        <div className='mb-2'>
                            <Select
                                options={options}
                                value={isOldFilter}
                                onSelect={(value) => handleFilterChange(value)}
                                placeholder={
                                    options.find(
                                        (option) =>
                                            option.value === isOldFilter,
                                    )?.label || options[2].label
                                }
                                style={{
                                    select: { width: '160px' },
                                    selectOptions: { width: '160px' },
                                }}
                            />
                        </div>
                        {paginatedCashboxes?.length > 0 && (
                            <Pagination
                                defaultCurrent={currentPage}
                                defaultPageSize={limit}
                                showQuickJumper={true}
                                showTotal={true}
                                total={cashboxesFiltred.length}
                                onChange={handlePaginationChange}
                                onShowSizeChange={handlePaginationChange}
                                hideOnSinglePage={true}
                            />
                        )}
                    </div>
                    {paginatedCashboxes?.length > 0 ? (
                        <>
                            {paginatedCashboxes.map((cashbox) => (
                                <>
                                    <div
                                        className={`relative w-full rounded-none border border-solid border-[#a4d0f1] bg-white p-4 mb-4 ${
                                            cashbox.is_old ? 'opacity-50' : ''
                                        }`}
                                    >
                                        <div className='relative grid grid-cols-1 gap-y-3 '>
                                            <div className='grid grid-cols-3 gap-4'>
                                                <p className='font-bold'>
                                                    {!cashbox.name ||
                                                    cashbox.name.length === 0
                                                        ? `caisse N° ${cashbox.id}`
                                                        : cashbox.name}
                                                </p>
                                                <div className=''>
                                                    <p className='font-bold text-[#51BEFF] text-xs mb-2'>
                                                        {t(
                                                            'CashBoxes.equipementList',
                                                        )}
                                                    </p>
                                                    <>
                                                        {cashbox?.cash_box_equipments?.map(
                                                            (eq, index) => (
                                                                <span
                                                                    className='font-bold text-black text-xs mb-2'
                                                                    key={index}
                                                                >
                                                                    {/* ajouter une "," si la taille des cahsbox equipements different de 0 sinon mettre un esapce */}
                                                                    {
                                                                        eq
                                                                            .equipment
                                                                            .name
                                                                    }{' '}
                                                                    {index !==
                                                                    cashbox
                                                                        .cash_box_equipments
                                                                        .length -
                                                                        1
                                                                        ? ','
                                                                        : ''}
                                                                </span>
                                                            ),
                                                        )}
                                                    </>
                                                </div>
                                                <div className='grid grid-cols-1 gap-4 justify-items-end mr-8'>
                                                    {cashbox.is_old && (
                                                        <UpdateCashBox
                                                            cashbox={cashbox}
                                                            site={cashbox.site} // Passez le site du cashbox à mettre à jour
                                                            refresh={getCashBox}
                                                            className='mt-2'
                                                        />
                                                    )}
                                                    {!cashbox.is_old && (
                                                        <>
                                                            <FaTrash
                                                                fill='red'
                                                                size={18}
                                                                onClick={() =>
                                                                    openConfirmModal(
                                                                        cashbox.id,
                                                                        cashbox.name,
                                                                    )
                                                                }
                                                                className='mt-2 cursor-pointer'
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </>
                    ) : (
                        <p>{t('CashBoxes.notFound')}</p>
                    )}
                </div>
            </Modal>

            <ConfirmationModal
                onOk={() => {
                    handleDelete(cashBoxToDelete.id, cashBoxToDelete.name);
                    setConfirmModalVisible(false);
                }}
                onCancel={() => setConfirmModalVisible(false)}
                visible={confirmModalVisible}
                confirmLoading={confirmLoading}
                content={<div>Voulez-vous vraiment supprimer la caisse?</div>}
            />
        </>
    );
};
export default ManageCashBoxButton;
