import React, { useEffect, useState } from 'react';
import SupportHomeCard from 'ck_commun/src/app/modWalletLocal/components/supportHomeCard/SupportHomeCard';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import './BadgesHome.css';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';
import i18next from 'i18next';

export default function BadgesHome() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { groupId, loadingGroup } = useSelector((state) => state.manager);
    const periods = [
        t('dates.period_today'),
        t('dates.period_week'),
        t('dates.period_month'),
        t('dates.period_year'),
    ];
    const today = [moment().format('YYYY[m]M[d]D')];
    const currentWeek = [moment().format('YYYY[w]W')];
    const currentMonth = [moment().format('YYYY[m]M')];
    const currentYear = [moment().format('YYYY')];
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            //appel de l'api badge_cumulative_get pour récupérer les cumules des badges par jour, par semaine, par mois et par année
            // await dispatch(
            //   getBadgesCumule({
            //     groupId,
            //     day: JSON.stringify(today),
            //     month: JSON.stringify(currentWeek),
            //     week: JSON.stringify(currentMonth),
            //     year: JSON.stringify(currentYear),
            //   })
            // );
            setLoading(false);
        }
        if (groupId) {
            fetchData();
        }
    }, [groupId, i18next.language]);
    return (
        <BuildingPage />
        //     <>
        //   <div className="flex-display flex-row justify-content-center" >
        //       <div className='info-box badgeInfoBox'>
        //         <div className='rounded-[10px_0px_0px_10px] flex justify-center items-center badgeInfoBoxIcon' style={{background: '#1B8ADC'}}>
        //          <img src={process.env.PUBLIC_URL+"/img/badgesIcons/euro.svg"}/>
        //         </div>
        //         <div className='bg-white rounded-[0px_10px_10px_0px] flex flex-col items-end justify-around p-[14px_19px_12px_5px] badgeInfoBoxContent'>
        //           <div className='text-right text-[15px] leading-[21px] text-[#8898aa] uppercase'><div>crédit</div><div>badges</div></div>
        //           <div className='text-[#4d4f5c] font-bold text-left badgeInfoBoxAmount'>621 €</div>
        //           <div className='text-right text-[12px] leading-[21px] text-black font-medium mt-[11px]'>dont <span style={{fontWeight: 'bold'}}>130 €</span> non utilisé de + 1 an</div>
        //         </div>
        //       </div>
        // {/** _______________*/}
        //       <div className='info-box badgeInfoBox'>
        //         <div className='rounded-[10px_0px_0px_10px] flex justify-center items-center badgeInfoBoxIcon' style={{background: '#11CDEF'}}>
        //         <img src={process.env.PUBLIC_URL+"/img/badgesIcons/badge.svg"}/>
        //         </div>
        //         <div className='bg-white rounded-[0px_10px_10px_0px] flex flex-col items-end justify-around p-[14px_19px_12px_5px] badgeInfoBoxContent'>
        //           <div className='text-right text-[15px] leading-[21px] text-[#8898aa] uppercase'><div>badges</div> <div>en circulation</div></div>
        //           <div className='text-[#4d4f5c] font-bold text-left badgeInfoBoxAmount'>621</div>
        //           <div className='text-right text-[12px] leading-[21px] text-black font-medium mt-[11px]'>dont <span style={{fontWeight: 'bold'}} >130</span> badges orphelins</div>
        //         </div>
        //       </div>
        //    </div>
        //     <div className='uppercase text-[17px] leading-[24px] text-black font-bold m-[20px] text-center'>Activité des badges d'abonnement</div>

        //     <div className="flex-display flex-row justify-content-center" >
        //      {
        //         periods?.map((period, index) => {
        //           return(
        //           <SupportHomeCard title={period} indexOfPeriod={index}/>)
        //          })
        //       }
        //     </div>

        //     </>
    );
}
